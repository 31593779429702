import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { formatDate, formatDistanceToNowCustom } from "../../../../utils";


////------ASYNC THUNK-------------------------
export const getCards = createAsyncThunk(
  "cards/getCards",
  async (_, { getState, dispatch }) => {
    const response = await getDocs(collection(db, "cards"),limit(2));
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result; }
    );

export const getCardsByPersonId = createAsyncThunk(
  "cards/getCardsByPersonId ",
  async (personId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "cards"), where("personId", "==", personId)), limit(2)
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: data.dateEvent ? formatDate(data.dateEvent) : null,

       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);

export const getCardsByUser = createAsyncThunk(
  "cards/getCardsByUser",
  async (userId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "cards"), where("userId", "==", userId)),limit(5)
    );
 const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);

export const getReactions = createAsyncThunk(
  "cards/getReactions",
  async (cardId, { getState, dispatch }) => {
    const response = await getDoc(collection(db, "cards"), cardId);
    const result = response.data().reactions;
    return result;
  }
);
export const updateReactions = createAsyncThunk(
  "cards/updateReactions",
  async (payload, { getState, dispatch }) => {
    const { cardId, reaction } = payload;

    const cardRef = doc(db, "cards", cardId);
    const card = await getDoc(cardRef);
    const cardData = card.data();
    const emotionValue = cardData.reactions[reaction];

   
    if (emotionValue !== undefined) {
      await updateDoc(cardRef, {
        reactions: {
          ...cardData.reactions,
          [reaction]: emotionValue + 1, 
        },
      });
    } else {
      console.error(`Invalid reaction type: ${reaction}`);
    }
  }
);

const initialState = {
  cards: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
  cardsUpdated: false,
};

export const cardSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    resetStateImageCard: (state) => {
     
      return { ...initialState };
    
    },
    addImageCard: (state, { payload }) => {
      state.cards.push(payload);
      state.cardsUpdated = true;
    },
    editImageCard: (state, { payload }) => {
      const cardIndex = state.cards.findIndex((card) => card.id === payload.id);
      if (cardIndex >= 0) {
        state.cards[cardIndex] = { ...state.cards[cardIndex], ...payload };
      } else {
        state.cards.push(payload);
      }
      state.cardsUpdated = true;
    },
    deleteImageCardd: (state, { payload }) => {
      state.cards = state.cards.filter((card) => card.id !== payload);
      state.cardsUpdated = true;
    },

    addReaction: (state, { payload }) => {
     /*  console.log("addReaction payload:", payload); // Add this line */
      const { cardId, reaction, userReactions } = payload;

      const existingCard = state.cards.find((card) => card.id === cardId);
      if (existingCard) {
        existingCard.reactions[reaction]++;
        if (userReactions) {
          if (!existingCard.userReactions) {
            existingCard.userReactions = {};
          }
          existingCard.userReactions[reaction] = userReactions;
        }
      }
    },
  },
  prepare(
    id,
    cardAuthor,
    userId,
    personId,
    fileURL,
    cardDesc,
    dateEvent,
    createdAt,
    userReactions,
    video
  ) {
    return {
      payload: {
        id,
        cardAuthor,
        userId,
        personId,
        reactions: {
          thumbsUp: 0,
          wow: 0,
          heart: 0,
          rocket: 0,
          coffee: 0,
        },
        userReactions: {
          thumbsUp: {},
          wow: {},
          heart: {},
          rocket: {},
          coffee: {},
        },
        video,
        fileURL,
        cardDesc,
        dateEvent,
        createdAt,
      },
    };
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getCards.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCards.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.cards = payload;
        state.error = null;
        state.cardsUpdated = false; 
      })
      .addCase(getCards.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      })
      .addCase(getCardsByPersonId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCardsByPersonId.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.cards = payload;
        state.error = null;
        state.cardsUpdated = false; 
      })
      .addCase(getCardsByPersonId.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
        
      })
      .addCase(getCardsByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCardsByUser.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.cards = payload;
        state.error = null;
        state.cardsUpdated = false; 
      })
      .addCase(getCardsByUser.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
        
      }).addCase(resetStateImageCard, (state) => {
        state.status = "idle";
         state.cards = [];
         state.error = null;
       });
    /* .addCase(updateReactions.fulfilled, (state, action) => {
      const { cardId, reaction } = action.payload;
      const existingPost = state.find((card) => card.id === cardId);
      if (existingPost) {
        existingPost.reactions[reaction] = !existingPost.reactions[reaction];
      }
    }
   ) */
  },
});

export const {addOrUpdateCardPicture,resetStateImageCard, addImageCard, editImageCard, deleteImageCardd, addReaction } = cardSlice.actions;
export const selectCardById = (state, personId) =>
  state.cards.cards.filter((card) => card.personId === personId);

export const selectAllPersonCard = (state) => state.cards.cards;
export const getCardStatus = (state) => state.cards.status;
export const getCArdError = (state) => state.cards.error;
export const cardsUpdate=(state)=>state.cards.cardsUpdated
// Memoizacija selektora
const selectCardsState = (state) => state.cards.cards;
const selectPersonId = (_, personId) => personId;
const selectCardId = (_, cardId) => cardId;
const selectUserId = (_, userId) => userId;
export const selectCardByCardId = createSelector(
  [selectCardsState, selectCardId],
  (cards, cardId) => cards.find((card) => card.id === cardId)
);

export const selectCardFilterByCardId = createSelector(
  [selectCardsState, selectCardId],
  (cards, cardId) => cards.filter((card) => card.id === cardId)
);

export const selectCardByUserId = createSelector(
  [selectCardsState, selectUserId],
  (cards, userId) => cards.filter((card) => card.userId === userId)
);


export const selectImageCardByPersonId = createSelector(
  [selectCardsState, selectPersonId],
  (cards, personId) => cards.filter((card) => card.personId === personId)
);


export default cardSlice.reducer;