import React from "react";
//MATERIAL UI
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { Divider, Typography } from "@mui/joy";

const DropdownMenu = ({ anchorEl, open, handleClose, navigate }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      variant="soft"
      keepMounted
      
      sx={{
        paddingTop: {xs:'2rem',sm:'1.5rem'},
        background: "linear-gradient( 135deg, #1B263B, #2F5370)",
        overflowY: 'auto', // dodajte ovu liniju
        maxHeight: '90vh',

      }}
    ><Typography level='h5' sx={{ paddingLeft: "0.8rem" ,color:'#AFC0D5',textAlign:'start'}}>Meni</Typography>
      
   
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("persons/svi-memoriali");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Online spomenici
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("persons/heroji-zajednice");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Heroji zajednice
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("persons/sehidi-poginuli-borci");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Šehidi-Poginuli borci
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("persons/rvi");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Ratni vojni invalidi
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("persons/demobilisani-borci");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Demobilisani borci
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("persons/genocid-masakr");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Žrtve genocida/masakra
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
    {/*   <MenuItem
          onClick={() => {
            handleClose();
            navigate("persons/sadasnji-memoari");
          }}
        
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Sadašnji Memoari
      </MenuItem>
      <Divider sx={{ padding: "1px" }} /> */}
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("info/krediti");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Krediti
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("info/faq");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Najčešća pitanja
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate("info/o-nama");
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        O nama
      </MenuItem>
    </Menu>
  );
};

export default DropdownMenu;
