import { styled } from "@mui/system";
import React from "react";
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Button from '@mui/joy/Button';

import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { CardContent } from "@mui/joy";
import { CardActions } from "@mui/material";
import { useState } from "react";
import { Loading } from "../../utils";
import { useNavigate } from "react-router-dom";
const CardHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: "#1B263B",
  color: "#B0C4DE",
  padding: theme.spacing(1, 2),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

const PricingCard = ({
  title,
  onClick,
  handlePayment,
  stripePaymentLink,
  credits,
  price,
  savings,
  promoPrice,
  promoInfo,
  onCheckoutClick,
  onButtonClick
}) => {
 
  const handleCheckout = () => {
    // Prevent the form from being submitted
   
    
    // Start the payment process
    handlePayment(stripePaymentLink);
  };
 
  return (
    

    <Card
      sx={{
        maxWidth: 345,
        backgroundImage:
          "#E1ECF8",
        boxShadow: 5,
      }}
    >
      <CardContent>
        <CardHeader>
          <Typography level="h3" fontWeight="sm" textColor="#FFEB3B">
          {credits} kredita
          </Typography>
        </CardHeader>

{promoPrice ? (
    <Box
        display="flex"
        flexDirection="column-reverse"
        alignItems="center"
    >
    <Typography
        level="h1"
        
        fontSize="xl4"
        sx={{ color: "#C62828", marginBottom: "0.5em" }}
    >
        <span style={{ marginRight: "0.2em" }}>{promoPrice}</span>KM
    </Typography>

    <Typography
        level="h3"
        component="h1"
        sx={{
          paddingTop:'0.5rem',
        color: "#E46A6A",
        fontSize: "xl",
        textDecoration: "line-through",
    }}
    >
    {(price / 100).toFixed(2)}
 KM
    </Typography>
    </Box>
) : (
    <Typography level="h3" >{(price / 100).toFixed(2)}
    KM</Typography>
)}
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          id="checkout-button"
          sx={{
            backgroundColor: "#FFC107",
            "&:hover": { backgroundColor: "#FFEB3B" },
            color: "#1B263B",
            fontWeight: "lg",
            fontSize:'lg'
          }}
          onClick={onClick}
        >
          Kupi
        </Button>
      </CardActions>
    </Card>
    
  );
};

export default PricingCard;

export const CardPrice =({credits,price, title,onClick,bonus,save,remained,pray,payLink})=>{
 

  const handleOnClick = () => {
    
    onClick(); 
   
  };
  return(
   
    <Card size="md" variant="solid" 
   
    sx={{ bgcolor: '#FFFFFF' }}>
      <Chip size="sm" variant="outlined" color="neutral">
        {bonus} 
      </Chip>
      <Typography level="h2" textColor="#1E3D59">{credits} Kredita</Typography> 
      <Divider inset="none" />
      <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
  <ListItem >
    <ListItemDecorator>
      <Check />
    </ListItemDecorator>
    <Typography level="body2"  textColor="#3C5A77">{save}</Typography> {/* Ušteda za ovaj paket */}
  </ListItem>
  <ListItem>
    <ListItemDecorator>
      <Check />
    </ListItemDecorator>
    <Typography level="body2"  textColor="#3C5A77">Pohranite do {credits}MB slika ili videozapisa</Typography>
  </ListItem>
  <ListItem>
    <ListItemDecorator>
      <Check />
    </ ListItemDecorator>
    <Typography level="body2"  textColor="#3C5A77">Dodajte {remained} novih uspomena za preminule (prva besplatna)</Typography>
  </ListItem>
  <ListItem>
    <ListItemDecorator>
      <Check />
    </ListItemDecorator>
    <Typography level="body2"  textColor="#3C5A77">Do {credits} tekstualnih uspomena</Typography>
  </ListItem>
  <ListItem>
    <ListItemDecorator>
      <Check />
    </ListItemDecorator>
    <Typography level="body2"  textColor="#3C5A77">1 do {pray} virtualnih molitvi (ovisno o molitvi)</Typography>
  </ListItem>
</List>
<Divider inset="none" />
<Typography 
  level="body1" 
  textColor="#4A7097" 
  sx={{ 
    mt: 2, 
    mb: 2,
    overflowWrap: 'break-word',
    wordWrap: 'break-word'
  }}
>
  Vaših {credits} kredita možete koristiti na bilo koji od gore navedenih načina. Bilo da želite pohraniti slike, dodati uspomene ili obaviti virtualne molitve - izbor je na vama!
</Typography>

<CardActions>
  <Typography level="title-lg"  textColor="#C62828" sx={{ mr: 'auto' }}>
  {(price / 100).toFixed(2)}KM{' '} 
  </Typography>
 
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
            sx={{ backgroundColor: "#FFC107", "&:hover": { backgroundColor: "#FFEB3B" }, color: "#1B263B" }}
            onClick={handleOnClick} 
          >
            Kupite odmah {/* Tekst dugmeta */}
          </Button>
        
       {/* <Button onClick={() => (window.location.href = payLink)}>Kupi preko payment linka</Button> */}

</CardActions>
    </Card>
 
 
  )
}