import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "../../redux/features/auth";
import { Loading } from "../../utils";
import { format } from "date-fns";
import { auth } from "../../firebase";



const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  rePassword: "",
};
export default function Register() {
  const dispatch = useDispatch();
const navigate = useNavigate();
  const [formReg, setFormReg] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const { firstName, lastName, email, password, rePassword } = formReg;
  const user = auth.currentUser ? auth.currentUser : null; 
  const signup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const date=new Date();
    const dateRegistration=format(date, 'dd.MM.yyyy')
    if (password === rePassword) {
      try {
      await dispatch(
          register({
            firstName,
            lastName,
            email,
            password,
            rePassword,
            dateRegistration,
          })
        );

   
       
      setFormReg("");
        
       
       setTimeout(() => {
        navigate(`/profile/${user?.uid}`);
      }, 100); 
      } catch (e) {
        setIsLoading(false);
        setErrorRegister(e.message);
       
      }
    } else {
      setIsLoading(false);
      setErrorRegister(e.message);
    }
  };


  const handleInputChangeUser = (e) => {
    const { name, value } = e.target;
    setFormReg({ ...formReg, [name]: value });
  };
  /* const handleInputFile = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile({ dataUrl: e.target.result, file: newFile });
      };
      reader.readAsDataURL(newFile);
    }
  }; */
  

  return (
    <>{isLoading && <Loading />}
    
      <Grid container component="main" sx={{ height: "100vh",pt:'2rem'}}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[500],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{zIndex:999,mb:"0.5rem"}}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#4A7097" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Registracija
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={signup}
              sx={{ mt: 3, mx: 4 }}
            >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6}>
                  <TextField
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#4A7097',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4A7097',
                      },
                    },
                  }}
                    aria-invalid={errorRegister ? "true" : null}
                    autoComplete="given-name"
                    name="firstName"
                    value={firstName}
                    required
                  
                    id="firstName"
                    label="Ime"
                    autoFocus
                    onChange={handleInputChangeUser}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#4A7097',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4A7097',
                      },
                    },
                  }}
                    aria-invalid={errorRegister ? "true" : null}
                    required
                    
                    id="lastName"
                    label="Prezime"
                    name="lastName"
                    value={lastName}
                    autoComplete="family-name"
                    onChange={handleInputChangeUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#4A7097',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4A7097',
                      },
                    },
                  }}
                    aria-invalid={errorRegister ? "true" : null}
                    required
                    
                    id="email"
                    label="Email Adresa"
                    name="email"
                    value={email}
                    autoComplete="email"
                    onChange={handleInputChangeUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#4A7097',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4A7097',
                      },
                    },
                  }}
                    aria-invalid={errorRegister ? "true" : null}
                    required
                    
                    name="password"
                    value={password}
                    label="Lozinka"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={handleInputChangeUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: '#4A7097',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4A7097',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4A7097',
                      },
                    },
                  }}
                    aria-invalid={errorRegister ? "true" : null}
                    required
                
                    name="rePassword"
                    value={rePassword}
                    label="Ponovi Lozinku"
                    type="password"
                    id="repassword"
                    autoComplete="new-password"
                    onChange={handleInputChangeUser}
                  />
                </Grid>
               {/*  <Grid item xs={12}>
                  <TextField
                    aria-invalid={errorRegister ? "true" : null}
                    label="Izaberite sliku"
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    
                    onChange={handleInputFile}
                  /> 
                </Grid> */}
              </Grid>
              <Button
                aria-busy={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#4A7097",
                  "&:hover": { bgcolor: "#2F5370" },
                }}
              >
                Registruj se
              </Button>
              <span>{errorRegister && errorRegister}</span>
              <Grid container justifyContent="flex-end">
                <Grid item>
             
                  <Link href="/auth/prijava" variant="body2"  sx={{ color: "#3C5A77" }}>
                  Već imate račun? Prijavite se
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
