import React from "react";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../assets/log3o.png";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/joy";
function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        bottom: 0,
        paddingTop:'1rem',
        width: "90%",
        
        display: "flex",
      }}
    >
      <Sheet
        variant="solid"
        invertedColors
        sx={{
          flexGrow: 1,
          minWidth: "100vw",
          p: 2,

          my: -3,
          borderRadius: { xs: 0, sm: "xs" },
          background: "linear-gradient( 135deg, #1B263B, #2F5370)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            paddingTop: 2,
            justifyContent: { xs: "center" },
            paddingLeft: "1rem",
            alignItems: { xs: "center", sm: "stretch" },
            gap: 1,
          }}
        >
          <List
            size="sm"
            orientation="horizontal"
            wrap
            sx={{
              flexGrow: 1,

              "--ListItem-radius": "8px",
              color: "#D1D1D1",
            }}
          >
            <Box
              onClick={() => navigate("/")}
              sx={{
                width: { xs: "100%", sm: "50%", md: "40%" },
                display: "flex",
                flexDirection: "column",
                paddingLeft: "3.5rem",
                color: "#B0C4DE",
                "&::before": {
                  content: '"Bosna i Hercegovina"',
                  position: "absolute",
                  left: "58px",
                  top: "-10px",
                },
              }}
            >
              <Typography
                variant="plain"
                level="h2"
                noWrap
                sx={{ color: "#E1ECF8" }}
              >
                In Memoriam
              </Typography>

              <img
                src={logo}
                alt="logo"
                width={60}
                height={60}
                style={{ position: "absolute", left: "0rem", top: "-0.5rem" }}
              />
              <Box>
              <Link href="https://www.facebook.com/profile.php?id=100094935986036" target="_blank" rel="noopener noreferrer">
  <IconButton variant="plain">
    <FacebookRoundedIcon style={{ color: "#AFC0D5" }} />
  </IconButton>
</Link>
               {/*  <IconButton variant="plain">
                  <InstagramIcon style={{ color: "#AFC0D5" }} />
                </IconButton>
                <IconButton variant="plain">
                  <TwitterIcon style={{ color: "#AFC0D5" }} />
                </IconButton>
                <IconButton variant="plain">
                  <LinkedInIcon style={{ color: "#AFC0D5" }} />
                </IconButton> */}
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "50%", md: "60%" },
                display: "flex",
                flexDirection:"row",
                position: "relative",
                color: "#B0C4DE",
                "&::before": {
                  position: "absolute",
                  left: "122px",
                  top: "-5px",
                },
              }}
            >
              <Box sx={{ width: { xs: "40%", sm: "33%" }, mx: "auto" }}>
                <ListItem nested sx={{ width: { xs: "250", sm: "250" } }}>
                  <ListSubheader>Informacije</ListSubheader>
                  <List>
                    <ListItem>
                      <ListItemButton onClick={() => navigate("info/o-nama")}>
                        O nama
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton onClick={() => navigate("info/faq")}>
                        Najčešća pitanja
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton onClick={() => navigate("info/kontakt")}>
                        Kontakt
                      </ListItemButton>
                    </ListItem>
                  </List>
                </ListItem>
              </Box>
              <Box sx={{ width: { xs: "40%", sm: "33%" }, mx: "auto" }}>
                <ListItem
                  nested
                  sx={{ width: { xs: "250", sm: "140", ml: { sm: "auto" } } }}
                >
                  <ListSubheader>Usluge</ListSubheader>
                  <List sx={{ "--List-decoratorSize": "32px" }}>
                    <ListItem>
                      <ListItemButton>Online spomenici</ListItemButton>
                    </ListItem>
                   {/*  <ListItem>
                      <ListItemButton>Živi kronološki zapis</ListItemButton>
                    </ListItem> */}
                  </List>
                </ListItem>
              </Box>
              <Box sx={{ width: { xs: "40%", sm: "33%" }, mx: "auto" }}>
                <ListItem nested sx={{ width: { xs: "250", sm: "140" } }}>
                  <ListSubheader>Politika privatnosti</ListSubheader>
                  <List>
                    <ListItem>
                      <ListItemButton onClick={() => navigate("info/zastita-privatnosti")}>Zaštita privatnosti</ListItemButton>
                    </ListItem>
                  </List>
                </ListItem>
              </Box>
            </Box>
          </List>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box
  sx={{
    display: "flex",
    width: "99%",
    flexDirection: { xs: "column", md: "row" },
    paddingLeft: "1rem",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <Typography variant="body2">
    © {new Date().getFullYear()} Hyper Efficient System, LLC. | Sva prava zadržana. | 
    <a href="https://heffsys.com" style={{ color: '#B0C4DE' }}>heffsys.com</a>
  </Typography>
</Box>

      </Sheet>
    </Box>
  );
}

export default Footer;
