import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  personsRviSlice,
  personsSlice,
  personsDemBorcilice,
  personsSehidiSlice,
  personsGenocidSlice,
  personsHeroeSlice,
} from "./features/persons";
import { userAuthSlice } from "./features/auth";
import { cardSlice } from "./features/cards";
import { videoSlice } from "./features/cards";
import { textSlice } from "./features/cards";
import { commentSlice } from "./features/comments/cards";
import { reactionsOnPersonSlice } from "./features/reactions";
import { commentVideoSlice } from "./features/comments/video";
import { commentTextSlice } from "./features/comments/text";
import {personsUserSlice} from "./features/persons/personsUserSlice";
import {personsIdSlice} from "./features/persons/personIdSlice";
import productsSlice  from "./features/products/productsSlice";
import personsSearchSlice from "./features/persons/personsSearchSlice";


const rootReducer = combineReducers({
  users: userAuthSlice.reducer,
  persons: personsSlice.reducer,
  personsUser: personsUserSlice.reducer,
  personsId: personsIdSlice.reducer,
  products: productsSlice,
  searchPerson: personsSearchSlice,
  cards: cardSlice.reducer,
  videoCards: videoSlice.reducer,
  textCards: textSlice.reducer,
  imageComments: commentSlice.reducer,
  videoComments: commentVideoSlice.reducer,
  textComments: commentTextSlice.reducer,
  reactionsOnPerson: reactionsOnPersonSlice.reducer,
  personsRvi: personsRviSlice.reducer,
  personsDemBorci: personsDemBorcilice.reducer,
  personsSehidi: personsSehidiSlice.reducer,
  personsGenocid: personsGenocidSlice.reducer,
  personsHeroes: personsHeroeSlice.reducer,
  
});

export default rootReducer;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),
  ///devtooll firebase redux tool
  devTools: process.env.NODE_ENV !== "production",
});
