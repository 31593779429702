import React from "react";

export const useHeaderState = () => {
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = React.useState(null);
  const [mobileMenuIconAnchorEl, setMobileMenuIconAnchorEl] =
    React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [menuMainOpen, setMenuMainOpen] = React.useState(false);
  const [menuMainIconOpen, setMenuMainIconOpen] = React.useState(false);
  const [menuProfilOpen, setMenuProfilOpen] = React.useState(false);
  const [menuProfilMobOpen, setMenuProfilMobOpen] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  return {
    mainMenuAnchorEl,
    setMainMenuAnchorEl,
    mobileMenuIconAnchorEl,
    setMobileMenuIconAnchorEl,
    anchorEl,
    setAnchorEl,
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
    menuMainOpen,
    menuMainIconOpen,
    menuProfilMobOpen,
    menuProfilOpen,
    setMenuMainOpen,
    setMenuMainIconOpen,
    setMenuProfilOpen,
    setMenuProfilMobOpen,
    isMenuOpen,
    isMobileMenuOpen,
  };
};

export const useHeaderHandlers = ({
  setMainMenuAnchorEl,
  setMobileMenuIconAnchorEl,
  setAnchorEl,
  setMobileMoreAnchorEl,
  setMenuMainOpen,
  setMenuMainIconOpen,
  setMenuProfilOpen,
  setMenuProfilMobOpen,
}) => {
//main menu
  const handleMainMenuOpen = (event) => {
    setMainMenuAnchorEl(event.currentTarget);
    setMenuMainOpen(true);
  };

  const handleMainMenuClose = () => {
    setMainMenuAnchorEl(null);
    setMenuMainOpen(false);
  };
//main menu icon
  const handleMobileMenuIconOpen = (event) => {
    setMobileMenuIconAnchorEl(event.currentTarget);
    setMenuMainIconOpen(true);
  };

  const handleMobileMenuIconClose = () => {
    setMobileMenuIconAnchorEl(null);
    setMenuMainIconOpen(false);
  };
//profile menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuProfilOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setMenuProfilOpen(false);
  };
  //profile menu icon
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setMenuProfilMobOpen(true);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setMenuProfilMobOpen(false);
  };

  

  
 
  return {
    handleMainMenuOpen,
    handleMainMenuClose,
    handleMobileMenuIconOpen,
    handleMobileMenuIconClose,
    handleProfileMenuOpen,
    handleMobileMenuClose,
    handleMenuClose,
    handleMobileMenuOpen,
  };
};
