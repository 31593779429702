import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db} from "../../../../firebase";


export const createTextCard = async ({
    id,
    personId,
    cardAuthor,
    userId,
    cardDescription,
    dateEvent,
    createdAt,
    title
  }) => {
    try {
      await setDoc(doc(db, "textCards", id), {
        id,
        userId,
        personId,
        reactions: {
          heart: 0,
        },
        userReactions: {},
        cardAuthor,
        cardDescription,
        dateEvent,
        createdAt,
        title
      });
    } catch (e) {
      /* console.error("Error adding document: ", e); */
    }
  };
  


export const UpdateTextCard = async ({
  id,
  cardDescription,
  dateEvent,
 
  title
}) => {
  try {
    const cardRef = doc(db, "textCards", id);
    await updateDoc(cardRef, {
      id,
      cardDescription,
      dateEvent,
     
      title
    });
  } catch (e) {
    /* console.error("Error updating card: ", e); */
  }
};


export const deleteTextCard = async ({ textId }) => {
  try {
    const cardRef = doc(db, "textCards", textId);
    await deleteDoc(cardRef);
  } catch (error) {
  /*   console.error(error); */
  }
};

export const updateReactionTextCard = async ({ textId, reaction, userId,avatar, author }) => {
  try {
    const textRef = doc(db, "textCards", textId);
    const text = await getDoc(textRef);

    const reactions = text.data().reactions || {};
    reactions[reaction] = (reactions[reaction] || 0) + 1;

    const userReactions = text.data().userReactions || {};
    userReactions[reaction] = userReactions[reaction] || [];

    if (!Array.isArray(userReactions[reaction])) {
      userReactions[reaction] = [];
    }

    if (!userReactions[reaction].some((userReaction) =>userReaction.userId === userId)) {
      userReactions[reaction].push({ userId, avatar, author });
    }

    await updateDoc(textRef, { reactions, userReactions })
      .then(() => {
       /*  console.log("Document successfully updated!"); */
      })
      .catch((error) => {
       /*  console.error("Error updating document: ", error); */
      });
  } catch (error) {
    /* console.error(error); */
  }
};
