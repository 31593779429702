import { CardOverflow, Typography, Sheet, Box } from "@mui/joy";
import PricingCard, { CardPrice } from "./PricingCard";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, resetProducts } from "../../redux/features/products";

import { initiateCheckout, selectUserData } from "../../redux/features/auth";
import { useState } from "react";

export const PricingPage = () => {
  
  const user = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [redirectingToStripe, setRedirectingToStripe] = useState(false);

  const products = useSelector((state) => state.products.entities);
  const loading = useSelector((state) => state.products.loading);

  useEffect(() => {
    dispatch(getProducts());

    return () => {
      if (loading === "idle") {
        dispatch(resetProducts());
      }
    };
  }, []);

  const handleCheckout = async (priceId, credits) => {
    setRedirectingToStripe(true); // Postavljanje indikatora preusmjeravanja na 'true'
    await dispatch(initiateCheckout({ priceId, credits }));
    // Ne postavljajte setRedirectingToStripe na false ovdje, jer će preusmjeravanje na Stripe verovatno već biti inicirano unutar initiateCheckout
  };
  
  let content;
  content = (
    <CardOverflow
      sx={{
       p:"2rem",
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(80%, 300px), 1fr))',
        gap: 2,
       
      }}
    >
      {Object.entries(products)
  .sort(([, a], [, b]) => a.metadata.rb - b.metadata.rb)
  .map(([productId, productData]) => {
    return (
      <Box key={productId}>
            <CardPrice
              
              price={productData.prices.priceData.unit_amount}
              credits={productData.metadata.credits}
              save={productData.metadata.save}
              pray={productData.metadata.pray}
              remained={productData.metadata.remained}
              title={productData.name}
              bonus={productData.description}
              onClick={() =>
                handleCheckout(
                  productData.prices.priceId,
                  productData.metadata.credits
                )
              }
            />
          </Box>
        );
      })}
    </CardOverflow>
  );

  // If you have more than one button, use a specific id or class to target the correct one

  return (
    <Sheet
      sx={{
        minWidth: "100vw",
        height: "auto",
        pt: "6.5rem",
        pb: "2rem",
        display: "flex",
        flexDirection: "column",
        
     
        bgcolor: "#34495E",
      }}
    >
      <Typography
        level="h1"
        fontWeight="md"
        align="center"
        textColor="#D1D1D1"
        sx={{
          paddingX: { xs: "1rem", sm: "2rem", md: "5rem", lg: "15rem" },
          fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem", lg: "3.5rem" },
        }}
        gutterBottom
      >
        Vaši Krediti, Vaše Mogućnosti
      </Typography>
      <Typography
        level="h5"
        fontWeight="md"
        align="left"
        textColor="#AFC0D5"
        gutterBottom
        sx={{
          paddingX: { xs: "1rem", sm: "2rem", md: "2.5rem", lg: "5rem" },
          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem", lg: "1.75rem" },
        }}
      >
        Kreditima možete pohraniti slike i videozapise, dodati nove uspomene za
        vaše najmilije koji su vas napustili, ostaviti tekstualne uspomene ili
        izraziti svoju duhovnost kroz virtualne molitve. Izbor je na vama!
      </Typography>
<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 2}}> {content}</Box>
     
    </Sheet>
  );
};


