import React from "react";
import algoliasearch from "algoliasearch/lite";
import { TiMediaRecordOutline } from "react-icons/ti";
import { AiOutlineLine } from "react-icons/ai";
import "instantsearch.css/themes/satellite.css";
/*  import "./algoliaSearch.css"  */
import {
  Container,
  Box,
  useMediaQuery,
  useTheme,
  CardActionArea,
} from "@mui/material";
import {
  AspectRatio,
  CardOverflow,
  Input,
  Typography,
} from "@mui/joy";
import {
  Configure,
  Highlight,
  InstantSearch,
  connectHits,
  connectSearchBox,
} from "react-instantsearch-dom";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils";
import { Timestamp } from "firebase/firestore";

const searchClient = algoliasearch(
  "791GDSMUTE",
  "638dfd9f610648d58dec39c82c71b89b"
);


const CustomHits = connectHits(({ hits, handlePersonClick }) => {
  return (
    <Box 
      display="flex" 
      flexWrap="wrap"
      gap={1}
      sx={{borderTop: "2px solid #E1ECF8", borderBottom: "2px solid #E1ECF8", marginTop: "8px"}}
    >
      {hits.map((hit) => (
        <Hit
          key={hit.objectID}
          hit={hit}
          handlePersonClick={handlePersonClick}
        />
      ))}
    </Box>
  );
});
const CustomSearchBox = ({ currentRefinement, refine, width }) => {
  return (
    <Input
      placeholder="Traži: Ime i Prezime..."
      variant="outlined"
      size="md"
      type="search"
      value={currentRefinement}
      onChange={(event) => {
        refine(event.currentTarget.value);
      }}
      endDecorator={
        <Typography
          component="span"
          variant="plain"
          level="body2"
          color="#1B263B"
          sx={{
            ml: 2,

            bgcolor: "transparent",
          }}
        >
          ⌘K
        </Typography>
      }
      sx={{
        "--Input-radius": "40px",
        "--Input-paddingInline": "12px",
        width,
        borderRadius: "md",
        color: "#0C4DE",
        bgcolor: "transparent",
      }}
    />
  );
};
const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

function SearchFilter({filter}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const width = isSmallScreen ? "80%" : "100%";
  const handlePersonClick = (path) => {
    navigate(`/${path}`);
  };
  return (
    <Container>
      <InstantSearch searchClient={searchClient} indexName="persons">
        <Configure hitsPerPage={8} filters={filter} />

        <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent="space-between">
         {/*  <Box>
            lova
            <RefinementList attribute="religija" />
          </Box> */}

          <Box>
            <ConnectedSearchBox width={width} />
            {/* <Stats
              translations={{
                stats(nbHits, timeSpentMS) {
                  return `${nbHits} rezultata pronađeno za ${timeSpentMS}ms`;
                },
              }}
            /> */} <CustomHits handlePersonClick={handlePersonClick} width={width} />
            <Box style={{ width }}>
             
            </Box>
          </Box>
        </Box>
      </InstantSearch>
    </Container>
  );
}

export default SearchFilter;

function Hit({ hit, handlePersonClick, width }) {
  const timestamp = hit.dateOfBirth;
  const firestoreTimestamp = Timestamp.fromMillis(timestamp);
  const formattedDateOfBirth = formatDate(firestoreTimestamp);
  const timestampDeath = hit.dateOfDeath;
  const firestoreTimestampD = Timestamp.fromMillis(timestampDeath);
  const formattedDateOfDeath = formatDate(firestoreTimestampD);

  return (
    <CardOverflow
      variant="outlined"
      sx={{
        width: { md: "236px", xs: "250px" },
        borderRadius: "14px",
        bgcolor: "#F2F4F8",
        transition: "transform 0.3s, border 0.3s",
        "&:hover": {
          transform: "scale(1.02)",
        },
      }}
    >
      <CardActionArea onClick={() => handlePersonClick(hit.path)}>
        <CardOverflow
          sx={{
            justifyContent: "center",
            border: "none",
            borderRadius: "0 0 14px 14px",
          }}
        >
          <AspectRatio
            ratio="2"
            sx={{
              borderRadius: "14px 14px 0 0",
              border: "none",
            }}
            variant="soft"
          >
            <img
              src={
                hit?.personURL ||
                "https://images.unsplash.com/photo-1492305175278-3b3afaa2f31f?auto=format&fit=crop&w=2000"
              }
              alt={hit.id || "User Profile"}
            />
          </AspectRatio>
        </CardOverflow>
      </CardActionArea>
      <Typography
        level="h5"
        fontWeight="lg"
        justifyContent="center"
        sx={{
          textAlign: "center",
          textTransform: "capitalize",
          mt: 2,
          color: "#1E3D59",
        }}
      >
        <Highlight attribute="firstName" hit={hit} />{" "}
        <Highlight attribute="lastName" hit={hit} />
     
      </Typography>
   
      <Typography
        level="body2"
        sx={{
          textAlign: "center",
          mt: 0.5,
          mb: 2,
          color: "#3C5A77",
        }}
      >
        {formattedDateOfBirth && `${formattedDateOfBirth} `}
        {formattedDateOfBirth && formattedDateOfDeath && (
          <>
            <TiMediaRecordOutline
              style={{ color: "#3C5A77", fontSize: "12px" }}
            />
            <AiOutlineLine
              style={{ color: "#3C5A77", marginLeft: -4, fontSize: "12px" }}
            />
            <TiMediaRecordOutline
              style={{ color: "#3C5A77", marginLeft: -4, fontSize: "12px" }}
            />
          </>
        )}
        {formattedDateOfDeath && ` ${formattedDateOfDeath}`}
      </Typography>
    </CardOverflow>
  );
}
