import {
    createAsyncThunk,
    createSelector,
    createSlice,
  } from "@reduxjs/toolkit";
  import { collection, getDocs, query, where } from "firebase/firestore";
  import { db } from "../../../../firebase";
import { formatDistanceToNowCustom } from "../../../../utils";
  
  const initialState = {
    textComments: [],
  };
  
  export const getTextComments = createAsyncThunk(
    "textComments/getTextComments",
    async (_, { getState }) => {
      const response = await getDocs(collection(db, "textComments"));
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          
       
         
        };
      });
      return result;
    
    }
  );
  
  export const getCommentsTxtByPersonId = createAsyncThunk(
    "textComments/getCommentsTxtByPersonId",
    async (personId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "textComments"), where("personId", "==", personId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );
  export const getCommentsByTexrId = createAsyncThunk(
    "textComments/getCommentsByTexrId",
    async (textId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "textComments"), where("textId", "==", textId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );
  export const getTextCommentsByUser = createAsyncThunk(
    "textComments/getTextCommentsByUser",
    async (userId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "textComments"), where("userId", "==", userId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );
  export const commentTextSlice = createSlice({
    name: "textComments",
    initialState,
    reducers: {
      resetStateCommentText: (state) => {
        return { ...initialState };
      },
      addTextComment: (state, { payload }) => {
        state.textComments.push(payload);
      },
      addReactionOntextComment: (state, { payload }) => {
       /*  console.log('addReactionOntextComment payload:', payload);  */
        const { commentId, reaction, userReactions } = payload;
          
        const existingComment = state.textComments.find((comment) => comment.id === commentId);
        if (existingComment) {
          existingComment.reactions[reaction]++;
          if (userReactions) {
            if (!existingComment.userReactions) {
              existingComment.userReactions = {};
            }
            existingComment.userReactions[reaction] = userReactions;
          }
        }
      }
    },
    
    extraReducers: (builder) => {
      builder.addCase(getTextComments.fulfilled, (state, { payload }) => {
        state.textComments = payload;
      })
      .addCase(getCommentsByTexrId.fulfilled,(state,{payload})=>{
        state.textComments=payload
      }).addCase(getCommentsTxtByPersonId.fulfilled,(state,{payload})=>{
        state.textComments=payload
      })
      /* builder.addCase(createComment.pending, (state) => {
    // Možete postaviti loading status ili raditi nešto slično ako je potrebno
  });
  builder.addCase(createComment.fulfilled, (state, { payload }) => {
    // Ažurirajte state sa novim komentarom, ako je potrebno
  });
  builder.addCase(createComment.rejected, (state, { error }) => {
    // Postavite error status ili radite nešto slično ako je potrebno
  }); */
    },
  });
  
  export const { addTextComment,addReactionOntextComment,resetStateCommentText } = commentTextSlice.actions;
  
  export default commentTextSlice.reducer;
  export const selectAllTextCommetns = (state) => state.textComments.textComments;
    export const selectTextCommentByTextId = createSelector(
      [(state) => state.textComments.textComments, (state, textId) => textId],
      (textComments, textId) => textComments.filter((comment) => comment.textId === textId)
    );
    
    
    export const selectTextCommentById = createSelector(
      [(state) => state.textComments.textComments, (state, commentId) => commentId],
      (textComments, commentId) => textComments.find((comment) => comment.id === commentId)
    );
    
    export const selectTextCommentByuserId = createSelector(
      [(state) => state.textComments.textComments, (state, userId) => userId],
      (textComments, userId) => textComments.filter((comment) => comment.userId === userId)
    );