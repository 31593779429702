import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, formatSoundRequests } from "../../../utils";

export const getPersonsByUser = createAsyncThunk(
    "persons/getPersonsByUser",
    async (userId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "persons"), where("userId", "==", userId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          dateOfDeath: formatDate(data.dateOfDeath),
          createdAt: formatDate(data.createdAt),
          dateOfBirth: formatDate(data.dateOfBirth),
          soundRequests: formatSoundRequests(data.soundRequests),
         
        };
      });
      return result;
    
    }
  );
  

  const initialState = {
    person: null, // Initialize as null
    status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  };
  
  export const personsUserSlice = createSlice({
    name: "personsUser",
    initialState,
    reducers: {
      resetStatePersonUser: (state) => {
        return { ...initialState };
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getPersonsByUser.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getPersonsByUser.fulfilled, (state, { payload }) => {
          state.status = "succeeded";
          state.person = payload;  // No need for check on alreadyFetched
        })
        .addCase(getPersonsByUser.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  });
  
  export const { resetStatePersonUser } = personsUserSlice.actions;
  
  const selectPersonsUserState = state => state.personsUser;
  export const selectPersonUser = createSelector(
    [selectPersonsUserState],
    personsUserState => personsUserState.person
  );
  

  export const selectPersonByUserId = createSelector(
   
    [selectPersonsUserState, (state, userId) => userId],
    
    (personsUserState, userId) =>
      personsUserState.persons.filter(person => person.userId === userId)
  );
  export const getPersonsUserStatus = (state) => state.personsUser.status;
  export const getPersonsUserError = (state) => state.personsUser.error;
  
  export default personsUserSlice.reducer;
  