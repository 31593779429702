import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/config";


/* export const getreactionOnPerson = createAsyncThunk(
  "reactionsOnPerson/getreactionOnPerson",
  async ({ personId, userId }) => {
    const reactionRef = collection(db, `reactionsPersons/${personId}`);
    const reactionsSnapshot = await getDocs(reactionRef);
    const reactionsData = reactionsSnapshot.docs.map((doc) => ({
      personId,
      userId,
      reaction: doc.id,
      userReactions: doc.data(), createdAt: formatDate(data?.createdAt),
    }));
    return reactionsData;
  }
); */


////------ASYNC THUNK-------------------------

export const getCardsByUser = createAsyncThunk(
  "cards/getCards",
  async (userId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "cards"), where("userId", "==", userId))
    );
    const result = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return result;
  }
);

export const getReactions = createAsyncThunk(
  "cards/getReactions",
  async (cardId, { getState, dispatch }) => {
    const response = await getDoc(collection(db, "cards"), cardId);
    const result = response.data().reactions;
    return result;
  }
);
export const updateReactions = createAsyncThunk(
  "cards/updateReactions",
  async (payload, { getState, dispatch }) => {
    const { cardId, reaction } = payload;

    const cardRef = doc(db, "cards", cardId);
    const card = await getDoc(cardRef);
    const emotionValue = card.data().reactions[reaction];
    if (emotionValue) {
      await setDoc(cardRef, {
        reactions: {
          heart: "",
        },
      });
    } else {
      await updateDoc(cardRef, {
        reactions: {
          ...card.data().reactions,
          [reaction]: !emotionValue,
        },
      });
    }
  }
);

//---------------------------------------------------------------------------------------
const initialState= {
  reactionsOnPerson: [],
  status: null,
};
export const reactionsOnPersonSlice = createSlice({
  name: "reactionsOnPerson",
  initialState,
  reducers: {
    resetStateReactions: (state) => {
     
      return { ...initialState };
    
    },
    addReaction: (state, action) => {
      const { personId, reaction, userId, userReaction } = action.payload;
      const existingReactionIndex = state.reactionsOnPerson.findIndex(
        (item) => item.personId === personId && item.userId === userId
      );
      if (existingReactionIndex !== -1) {
        state.reactionsOnPerson[existingReactionIndex].userReactions[reaction] =
          userReaction;
      } else {
        state.reactionsOnPerson.push({
          personId,
          userId,
          userReactions: {
            [reaction]: userReaction,
          },
        });
      }
    },
    setReactionsOnPerson: (state, action) => {
      state.reactionsOnPerson = action.payload;
    },
    clearReactionsOnPerson: (state) => {
      state.reactionsOnPerson = [];
    },
  },
  /* extraReducers: (builder) => {
    builder
      .addCase(getreactionOnPerson.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getreactionOnPerson.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reactionsOnPerson = action.payload;
      })
      .addCase(getreactionOnPerson.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }, */
});

export const { addReaction, setReactionsOnPerson, clearReactionsOnPerson,resetStateReactions } =
  reactionsOnPersonSlice.actions;

export default reactionsOnPersonSlice.reducer;

export const selectCardById = (state, personId) =>
  state.cards.cards.filter((card) => card.personId === personId);

export const selectAllPersonCard = (state) => state.cards.cards;
export const getCardStatus = (state) => state.cards.status;
export const getCArdError = (state) => state.cards.error;

export const selectCardByCardId = (state, cardId) =>
  state.cards.cards.find((cards) => cards.id === cardId);

export const selectCardFileterByCardId = (state, cardId) =>
  state.cards.cards.filter((cards) => cards.id === cardId);

export const selectCardByUserId = (state, userId) =>
  state.cards.cards.filter((cards) => cards.userId === userId);