import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage } from "../../../firebase";

const uploadImage = async (id, personURL, onProgress) => {
  const storageRef = ref(storage, `persons-pictures/${id}-${Date.now()}`);
  const uploadTask = uploadBytesResumable(storageRef, personURL);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (error) => {
        console.log(error.message);
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log("File available at", downloadURL);
        resolve(downloadURL);
      }
    );
  });
};
export const updateImage = async (
  id, 
  oldPictureURL,
  pictureFile,
  onProgress
) => {
  // Ako stari URL slike postoji, pokušajte ga izbrisati
  if (oldPictureURL) {
    try {
      const storageRef = ref(storage, oldPictureURL);
      await deleteObject(storageRef);
      console.log("Stara slika je uspjesno izbrisana");
    } catch (error) {
      console.log("Error deleting old image:", error);
    }
  }

  // Postavi novu sliku
  if (pictureFile) {
    try {
      const storageRef = ref(storage, `persons-pictures/${id}-${Date.now()}`);
      const uploadTask = uploadBytesResumable(storageRef, pictureFile);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (typeof onProgress === 'function') {
              onProgress(progress);
            } else {
              console.error("onProgress nije funkcija");
            }
          },
          (error) => {
            console.error("Error uploading file:", error);
            reject(error);
          },
          async () => {
            const personURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(personURL);
          }
        );
      });
    } catch (error) {
      console.error("Error uploading new image:", error);
      throw error;
    }
  }
};
export const updateBGImage = async (
  id, 
  oldPictureURL,
  pictureFile,
  onProgress
) => {
  // Ako stari URL slike postoji, pokušajte ga izbrisati
  if (oldPictureURL) {
    try {
      const storageRef = ref(storage, oldPictureURL);
      await deleteObject(storageRef);
      console.log("Stara slika je uspjesno izbrisana");
    } catch (error) {
      console.log("Error deleting old image:", error);
    }
  }

  // Postavi novu sliku
  if (pictureFile) {
    try {
      const storageRef = ref(storage, `persons-bg-pictures/${id}-${Date.now()}`);
      const uploadTask = uploadBytesResumable(storageRef, pictureFile);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (typeof onProgress === 'function') {
              onProgress(progress);
            } else {
              console.error("onProgress nije funkcija");
            }
          },
          (error) => {
            console.error("Error uploading file:", error);
            reject(error);
          },
          async () => {
            const personBGImage = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(personBGImage);
          }
        );
      });
    } catch (error) {
      console.error("Error uploading new image:", error);
      throw error;
    }
  }
};

export const createPerson = async ({
  id,
  userId,
  genocid,
  personId,
  father,
    mom,
  genocidLocation,
  rvi,
  sehid,
  orden,
  demBorac,
  vatrogasci,
  policija,
  spasioci,
  ucitelji,
  volonteri,
  medRadnici,
  createdOf,
  firstName,
  lastName,
  lastName2,
  religija,
  gender,
  biografija,
  addressOfSahrana,
  dateOfHeroes,
  dateOfDeath,
  dateOfBirth,
  createdAt,
  addressOfBirth,
  addressOfDeath,
  countryOfDeath,
  countryOfBirth,
  personURL,
  onProgress,
   
}) => {
  try {
    const downloadURL = await uploadImage(id, personURL, onProgress);
    const docRef = doc(db, "persons", id);
    const reactions = {
      hatma: 0,
      fatiha: 0,
      jasin: 0,
      cvijece: 0,
      pray: 0,
      candle: 0,
      krunica: 0,
      pravKriz: 0,
      saint: 0,
    };
    const userReactions = {
      hatma: {},
      fatiha: {},
      jasin: {},
      cvijece: {},
      pray: {},
      candle: {},
      krunica: {},
      pravKriz: {},
      saint: {},
    };
    const personData = {
      personId,
      userId,
      genocid,
      genocidLocation,
      rvi,
      father,
    mom,
      sehid,
      orden,
      demBorac,
      vatrogasci,
      policija,
      spasioci,
      ucitelji,
      volonteri,
      medRadnici,
      createdOf,
      firstName,
      lastName,
      lastName2,
      religija,
      gender,
      biografija,
      addressOfSahrana,
      dateOfHeroes,
      dateOfDeath,
      dateOfBirth,
      createdAt,
      addressOfBirth,
      addressOfDeath,
      countryOfDeath,
      countryOfBirth,
      reactions,
      userReactions,
      personURL: downloadURL,
    };
    await setDoc(docRef, personData);
    console.log("Document successfully added");
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const updatePerson = async ({
  id,
  firstName,
  lastName,
  lastName2,
  father,
    mom,
  genocid,
  genocidLocation,
  rvi,
  sehid,
  orden,
  demBorac,
  createdOf,
  religija,
  gender,
  biografija,
  addressOfSahrana,
  dateOfHeroes,
  dateOfDeath,
  dateOfBirth,
  addressOfBirth,
  addressOfDeath,
  countryOfDeath,
  countryOfBirth,
  vatrogasci,
  policija,
  spasioci,
  ucitelji,
  volonteri,
  medRadnici,
  reactions,
  personBGImage,
  userReactions,
  personURL,
  onProgress,
}) => {
  try {
    const docRef = doc(db, "persons", id);

    const updateData = {
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(lastName2 && { lastName2 }),
      ...(genocid && { genocid }),
      ...(father && { father }),
      ...(mom && { mom }),
      ...(genocidLocation && { genocidLocation }),
      ...(rvi && { rvi }),
      ...(sehid && { sehid }),
      ...(orden && { orden }),
      ...(demBorac && { demBorac }),
      ...(createdOf && { createdOf }),
      ...(religija && { religija }),
      ...(gender && { gender }),
      ...(biografija && { biografija }),
      ...(addressOfSahrana && { addressOfSahrana }),
      ...(dateOfHeroes && { dateOfHeroes }),
      ...(dateOfDeath && { dateOfDeath }),
      ...(dateOfBirth && { dateOfBirth }),
      ...(addressOfBirth && { addressOfBirth }),
      ...(addressOfDeath && { addressOfDeath }),
      ...(countryOfDeath && { countryOfDeath }),
      ...(countryOfBirth && { countryOfBirth }),
      ...(vatrogasci && { vatrogasci }),
      ...(policija && { policija }),
      ...(spasioci && { spasioci }),
      ...(ucitelji && { ucitelji }),
      ...(volonteri && { volonteri }),
      ...(medRadnici && { medRadnici }),
      ...(reactions && { reactions }),
      ...(userReactions && { userReactions }),
      ...(personURL && { personURL }),
      ...(personBGImage && {personBGImage}),
    };

    await updateDoc(docRef, updateData,onProgress);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const updateReactionCoun = async ({ personId, reaction, userId }) => {
  try {
    const personRef = doc(db, "persons", personId);
    const person = await getDoc(personRef);

    const reactions = person.data().reactions || {};
    reactions[reaction] = (reactions[reaction] || 0) + 1;

    const userReactions = person.data().userReactions || {};
    userReactions[reaction] = userReactions[reaction] || [];
    if (!userReactions[reaction].includes(userId)) {
      userReactions[reaction].push(userId);
    }

    await updateDoc(personRef, { reactions, userReactions })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const updateReactionCount = async ({
  personId,
  reaction,
  userId,
  soundFiles,
  timeReaction,
  avatar,
  author,

}) => {
  try {
    const personRef = doc(db, "persons", personId);
    const person = await getDoc(personRef);

    const reactions = person.data().reactions || {};
    reactions[reaction] = (reactions[reaction] || 0) + 1;

    const soundRequests = person.data().soundRequests || {};
    soundRequests[reaction] = soundRequests[reaction] || [];
    soundRequests[reaction].push({
      userId,
      timeReaction: timeReaction,
      avatar,
      author,
      soundFile: soundFiles[reaction],
     
    });

    await updateDoc(personRef, { reactions, soundRequests })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  } catch (error) {
    console.error(error);
  }
};



export const checkMolitvaStatus = async (personId, reaction) => {
  try {
    const personRef = doc(db, "persons", personId);
    const person = await getDoc(personRef);
    const soundRequests = person.data().soundRequests || {};
    const soundRequest = soundRequests[reaction]?.pop(); 
    if (soundRequest) {
      const currentTime = new Date();
      const startTime = soundRequest.startTime.toDate();
      const endTime = new Date(startTime.getTime() + soundRequest.duration * 1000);

      if (currentTime < endTime) {
        // Molitva je još uvijek u tijeku
        return true;
      }
    }

    // Molitva nije u tijeku ili nije pronađena
    return false;
  } catch (error) {
    console.error(error);
    return false; // Vratite false ako se dogodi greška
  }
};