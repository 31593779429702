import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, query, collection, where } from "@firebase/firestore";
import { db } from "../../../firebase";

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async () => {
    const productsQuery = query(
      collection(db, "products"),
      where("active", "==", true)
    );
    const productSnapshot = await getDocs(productsQuery);

    const products = {};
    for (const productDoc of productSnapshot.docs) {
      products[productDoc.id] = productDoc.data();
      const priceSnapshot = await getDocs(
        collection(db, `products/${productDoc.id}/prices`)
      );
      priceSnapshot.docs.forEach((priceDoc) => {
        products[productDoc.id].prices = {
          priceId: priceDoc.id,
          priceData: priceDoc.data(),
        };
      });
    }
    return products;
  }
);
const initialState = { entities: [], loading: "idle", error: null };

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    resetProducts: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = "idle";
        state.entities = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.error.message;
      });
  },
});

export default productsSlice.reducer;
export const { resetProducts } = productsSlice.actions;
