import React from "react";
import { Container, Typography } from "@mui/joy";

function Privacy() {
  return (
    <Container maxWidth="md" sx={{ pt: "6rem", pb: "4rem" }}>
      <Typography
        level="h1"
        sx={{
          color: "#1E3D59",
          mb: 2,
          fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem", lg: "3.5rem" },
        }}
      >
        POLITIKA ZAŠTITE PRIVATNOSTI
      </Typography>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        1. Pozadina{" "}
      </Typography>
      <Typography
        level="h3"
        fontSize="md"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1rem", sm: "1.25rem" },
        }}
      >
        Ova politika privatnosti ("Politika privatnosti") objašnjava kako
        inmemoriam-bosna.ba namjerava prikupljati, čuvati, koristiti, otkrivati,
        zaštititi, rukovati i na drugi način obraditi vaše lične podatke. Mi
        radimo unutar i poštujemo Važeće zakone o privatnosti. Više detalja o
        Važećim zakonima o privatnosti možete pronaći na web stranici Relevantne
        nadležne institucije.
      </Typography>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        2. Definicije
      </Typography>{" "}
      <Typography
        level="h3"
        fontSize="md"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1rem", sm: "1.25rem" },
        }}
      >
        Osim ako nije izričito drugačije navedeno, termini korišćeni u ovoj
        Politici privatnosti imaju značenje dato u našim Uslovima korišćenja:
        <ul>
          <li>
            "Važeći zakoni o privatnosti" znači: (a) Ako je relevantna
            jurisdikcija država članica Evropske unije, General Data Protection
            Regulation (EU) 2016/679 (kako su izmijenjeni ili zamijenjeni s
            vremena na vreme); (b) Ako je relevantna jurisdikcija Sjedinjene
            Američke Države, primenjuju se zakoni o privatnosti te države;
          </li>
          <li>
            "Informacije o kolačićima" imaju značenje dato tom pojmu u klauzuli
            5;
          </li>
          <li>
            "Uslovi" znači uslove korišćenja inmemoriam-bosna.ba koje možete
            pronaći na sledećem na našem linku.
          </li>
          <li>
            "Politika privatnosti" ima značenje dato tom pojmu u klauzuli 1;
          </li>
          <li>
            "Lični podaci": (a) Ako je relevantna jurisdikcija država članica
            Evropske unije, ima isto značenje kao i termin 'lični podaci' prema
            General Data Protection Regulation (EU) 2016/679 (kako su
            izmijenjeni ili zamijenjeni s vremena na vreme); (b) Ako je
            relevantna jurisdikcija Sjedinjene Američke Države, znači sve
            informacije koje inmemoriam-bosna.ba prikuplja, elektronski ili
            ručno, preko (i) web stranice, (ii) bilo koje usluge, (iii) e-mail
            poruka i drugih elektronskih komunikacija koje možete poslati
            inmemoriam-bosna.ba, i (iv) drugih izvora u toku našeg poslovanja,
            koji se odnose na pojedinca i koji identifikuju, ili se mogu
            koristiti u kombinaciji s drugim informacijama da razumno
            identifikuju, takvog pojedinca;
          </li>
          <li>
            "Usluga" znači usluga koju pruža inmemoriam-bosna.ba na web
            stranici;
          </li>
          <li>
            "Korisnik" znači korisnika usluga koje inmemoriam-bosna.ba pruža
            putem web stranice;
          </li>
          <li>
            "Web stranica" znači bilo koju web stranicu koju hostuje i upravlja
            inmemoriam-bosna.ba;
          </li>
          <li>
            "Relevantna nadležna institucija" znači: (a) Ako je relevantna
            jurisdikcija država članica Evropske unije, vlada ili agencija koja
            je zadužena za sprovođenje General Data Protection Regulation (EU)
            2016/679 (kako su izmijenjeni ili zamijenjeni s vremena na vreme);
            (b) Ako je relevantna jurisdikcija Sjedinjene Američke Države, vlada
            ili agencija koja je zadužena za sprovođenje relevantnih zakona o
            privatnosti te države.
          </li>
          <li>
            "Osjetljive informacije" znači sve lične podatke pojedinca koji
            otkrivaju (i) rasno ili etničko podrijetlo, (ii) politička
            uvjerenja, religijska ili filozofska uvjerenja, (iii) članstvo u
            sindikatu, (iv) genetske podatke, (v) biometrijske podatke; (vi)
            podatke o fizičkom ili mentalnom zdravlju, (vii) seksualni život ili
            seksualnu orijentaciju, ili (viii) krivični dosije takvog pojedinca.
          </li>
          <li>
            "Usluga" znači usluga koju pruža inmemoriam-bosna.ba na web
            stranici.
          </li>
          <li>
            "Podružnice" imaju značenje dato tom terminu u Zakonu o
            korporacijama 2001 (Cth).
          </li>
          <li>
            "Uslovi" znači uslove korišćenja inmemoriam-bosna.ba i uslove i
            odredbe bilo koje web stranice koja se može pristupiti na
            relevantnoj web stranici.
          </li>
          <li>
            "Korisnik" znači korisnika usluga koje inmemoriam-bosna.ba pruža
            putem web stranice.
          </li>
          <li>
            "Web stranica" znači bilo koju web stranicu koju hostuje i upravlja
            inmemoriam-bosna.ba, uključujući web stranice koje upravlja neka od
            njenih podružnica.
          </li>
        </ul>
      </Typography>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        3. Prikupljanje i čuvanje ličnih informacija i podataka
      </Typography>{" "}
      <ul style={{ listStyleType: "none" }}>
        {" "}
        <Typography
          level="body1"
          component="h1"
          fontWeight="lg"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
          }}
        >
          {" "}
          3.1. Vrste ličnih informacija
        </Typography>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li>
            Možemo prikupljati lične informacije o pojedincima. Ove lične
            informacije mogu obuhvatati: (a) imena; (b) pol; (c) kontakt podatke
            (uključujući e-mail adresu, broj(eve) telefona i ostale kontakt
            podatke); (d) datum rođenja; (e) korisnička imena i lozinke; (f)
            fotografije; (g) podatke o bankovnom računu; (h) datum smrti; (i)
            videozapise; i (j) Osjetljive informacije koje odlučite dostaviti
            nama. In Memoriam Bosna prikuplja ove lične informacije radi svojih
            legitimnih interesa, kao što su marketing svojih proizvoda i usluga
            vama, pružanje, administriranje, poboljšanje i personalizacija
            usluga vama, te na osnovu vaše saglasnosti, kada In Memoriam Bosna
            zatraži i prikupi vašu saglasnost prije prikupljanja, čuvanja,
            korištenja, otkrivanja, zaštite i rukovanja ličnim informacijama.
          </li>
        </Typography>
        <Typography
          level="body1"
          component="h1"
          fontWeight="lg"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
          }}
        >
          {" "}
          3.2 Ograničenja
        </Typography>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li>
            Ako In Memoriam Bosna ne prikupi sve ili neke od vaših ličnih
            informacija, In Memoriam Bosna možda neće moći pružiti vam uslugu (u
            cijelosti ili djelimično) ili personalizirati uslugu (u cijelosti
            ili djelimično) za vas, niti vam pružiti druge proizvode i usluge.
            Ako imate manje od 16 godina, potrebna nam je saglasnost vaših
            roditelja ili staratelja za obradu vaših ličnih informacija. In
            Memoriam Bosna namjerno ne obrađuje lične informacije djece mlađe od
            16 godina. Ako imate manje od 16 godina, molimo vas da ne koristite
            usluge bez saglasnosti vaših roditelja ili staratelja. Ako sumnjate
            da je dijete mlađe od 16 godina pružilo lične informacije nama,
            molimo vas da nas obavijestite putem e-mail adresa na
            [podrska@inmemoriam-bosna.ba].
          </li>
        </Typography>
        <Typography
          level="body1"
          component="h1"
          fontWeight="lg"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
          }}
        >
          {" "}
          3.3. Osjetljive informacije
        </Typography>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li>
            Lične informacije prikupljene od strane In Memoriam Bosna mogu
            sadržavati Osjetljive informacije. In Memoriam Bosna će Osjetljive
            informacije prikupljati i koristiti samo u svrhu za koju su
            prikupljene, u skladu sa Primjenjivim zakonima o privatnosti, što
            uključuje situacije kada ste dali svoju izričitu saglasnost, kada je
            takva obrada potrebna u oblasti zapošljavanja, socijalne sigurnosti
            ili zakona o socijalnoj zaštiti, ili kada je takva obrada potrebna
            za uspost
          </li>
        </Typography>
        <Typography
          level="body1"
          component="h1"
          fontWeight="lg"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
          }}
        >
          {" "}
          3.4 Kako In Memoriam Bosna prikuplja vaše lične informacije?
        </Typography>{" "}
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li>
            In Memoriam Bosna će prikupljati lične informacije samo putem
            zakonitih i fer metoda, a ne na način koji je neopravdano nametljiv.
            Kada pružate In Memoriam Bosnau lične informacije, saglasni ste s
            korištenjem, otkrivanjem i rukovanjem vašim ličnim informacijama u
            skladu s ovom Politikom privatnosti. Prikupljanje informacija od vas
            Ako je razumno i izvodljivo, In Memoriam Bosna će prikupljati lične
            informacije izravno od vas. Ovisno o načinu na koji izaberete da
            komunicirate s In Memoriam Bosnaom, In Memoriam Bosna može prikupiti
            vaše lične informacije kada se obratite In Memoriam Bosnau ili
            njegovim pružateljima usluga telefonom, e-mailom, putem web stranice
            ili kada popunite obrazac ili dokument i dostavite ga In Memoriam
            Bosnau. Konkretno, prikupljamo lične informacije u sljedećim
            situacijama: (a) osoba se prijavi kao administrator stranice ili
            gost korisnik; (b) osoba se prijavi za korisnički račun na web
            stranici da postane korisnik; (c) korisnik objavljuje sadržaj o sebi
            na web stranici; (d) korisnik objavljuje sadržaj o trećoj osobi koja
            je živa na web stranici; (e) korisnik šalje sadržaj o sebi ili živoj
            trećoj osobi sebi ili trećoj osobi na web stranici; (f) korisnik
            posjeti web stranicu i pošalje upit ili ostavi komentar ili drugi
            post na stranici, putem e-maila, putem platforme društvenih medija
            poput Facebooka, Twittera ili Pinteresta; (g) korisnik se prijavi na
            popis e-pošte za marketinške svrhe; (h) korisnik koristi naše usluge
            društvenih medija; (i) sastajemo se s organizacijom koja želi
            poslovati s In Memoriam Bosnaom (i pojedinac iz te organizacije
            pruža detalje); (j) pojedinac obavlja transakciju s nama koristeći
            kreditnu karticu ili drugu metodu osim gotovine; (k) pojedinac
            naručuje putem naše online trgovine u skladu s In Memoriam Bosna
            Uvjetima; (l) pojedinac sudjeluje u natjecanju koje organizira In
            Memoriam Bosna; i (m) procjena prijava za posao i osoblje, što može
            uključivati prikupljanje detalja poput radne povijesti i
            kvalifikacija. To može uključivati i Osjetljive informacije. Daljnji
            detalji o našoj Politici privatnosti i praksama za prijavitelje
            posla bit će dostupni prilikom prijave. Ne prikupljamo izravno
            podatke o vašoj kreditnoj kartici radi obrade vaše uplate ili u
            svrhu korisničke podrške. Vaši podaci o kreditnoj kartici
            prikupljaju se, obrađuju i čuvaju izravno od strane trećih procesora
            plaćanja u skladu s njihovim uvjetima korištenja i politikama
            privatnosti. Nijedan podatak o vašoj kreditnoj kartici trajno se ne
            čuva kod nas. Međutim, možemo prikupiti podatke o vašem bankovnom
            računu radi obrade povrata sredstava. Prikupljanje informacija od
            trećih strana. In Memoriam Bosna također može prikupljati
            informacije o vama od drugih ljudi (npr. administratora treće strane
            ili drugih korisnika) ili neovisnih izvora. To može biti slučaj
            kada, primjerice, drugi korisnici učitavaju sadržaj koji uključuje
            informacije o vama ili kada vas administrator stranice imenuje za
            administratora stranice u skladu s In Memoriam Bosna Uvjetima ili
            kada ste imenovani kao administrator za račun na web stranici. Ako
            nam također date dozvolu za pristup informacijama na drugim
            uslugama, poput Facebooka ili Google Drivea, Applea ili Dropboxa,
            možemo dobiti informacije iz tih računa ovisno o vašim postavkama
            privatnosti. Međutim, In Memoriam Bosna će to učiniti samo ako nije
            razumno i izvodljivo prikupiti informacije izravno od vas. Ako je In
            Memoriam Bosna prikupio vaše lične informacije od treće strane,
            takve lične informacije će se čuvati, koristiti i otkrivati od
            strane In Memoriam Bosna u skladu s ovom Politikom privatnosti.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        4. Cookies/Kolačići
      </Typography>
      <ul>
        {" "}
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            In Memoriam Bosna također može prikupljati informacije na temelju
            načina na koji koristite njegovu web stranicu, uključujući putem
            "kolačića", web oznaka i drugih sličnih tehnologija. Kolačići su
            male tekstualne datoteke koje se putem web preglednika prenose na
            tvrdi disk vašeg računala kako bi In Memoriam Bosnaovi sustavi
            prepoznali vaš preglednik i zabilježili nelične informacije poput
            datuma, vremena ili trajanja vaše posjete i pristupanih stranica u
            svrhu administracije web stranice, statističkih i održavanje
            ("Informacije o kolačićima"). Možemo koristiti "kolačiće" kako bismo
            prikupljali podatke vezane uz vašu opću internetsku upotrebu.
            Koristimo kolačiće kako bismo nam pomogli poboljšati našu stranicu i
            pružiti bolju i prilagođeniju uslugu, na primjer, pohranjivanjem
            informacija o vašim preferencijama i omogućavanjem prepoznavanja
            kada se vratite na našu stranicu. Zatražit ćemo vašu suglasnost
            prije postavljanja neobaveznih kolačića na vaš uređaj. Kada
            pojedinci koriste jednu od naših mobilnih aplikacija, također možemo
            prikupljati informacije o lokaciji vašeg grada, modelu i verziji
            uređaja i verziji operativnog sustava. In Memoriam Bosna ne pokušava
            koristiti Informacije o kolačićima kako bi vas osobno identificirao.
            Međutim, ako se Informacije o kolačićima povežu s ličnim
            informacijama kako je gore navedeno, te Informacije o kolačićima
            postaju lične informacije i bit će tretirane na isti način kao lične
            informacije s kojima su povezane. Možete ukloniti ili odbiti
            kolačiće podešavanjem postavki na web pregledniku. Imajte na umu da
            neki dijelovi web stranice možda neće potpuno funkcionirati za
            korisnike koji onemogućavaju kolačiće.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        5. Elektronska komunikacija i izravni marketing
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            In Memoriam Bosna može, u skladu s zakonima i propisima Primjenjive
            jurisdikcije i Primjenjivih zakona o privatnosti, slati vam e-mail
            poruke i druge elektronske komunikacije (i) u vezi s korištenjem web
            stranice ili bilo kojih usluga, (ii) u redovitom poslovanju ili
            (iii) u druge legitimne poslovne svrhe (uključujući, ali ne
            ograničavajući se na marketing). U svakom trenutku možete odjaviti
            primanje takvih e-mail poruka ili drugih elektronskih komunikacija
            slanjem e-maila In Memoriam Bosnau na podrska@inmemoriam-bosna.ba
            ili info@inmemoriam-bosna.ba ili slijedeći upute sadržane u tim e-mail
            porukama ili drugim elektronskim komunikacijama.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        6. Anonimnost/pseudonimnost
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Gdje je to izvedivo, kupci i drugi imaju pravo da interaktiraju s
            nama anonimno ili koristeći pseudonim. Međutim, situacije u kojima
            to nije izvedivo uključuju slučajeve kada su lične informacije
            potrebne za završetak transakcije s kreditnom karticom ili računa.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        7. Korištenje ličnih informacija
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            In Memoriam Bosna može držati, koristiti ili otkriti vaše lične
            informacije u marketinške svrhe, ali samo ako takva upotreba
            ispunjava Primjenjive zakone o privatnosti. U skladu s Primjenjivim
            zakonima o privatnosti, In Memoriam Bosna također može držati,
            koristiti ili otkriti vaše lične informacije prikupljene u svrhu za
            koju su pružene ili prikupljene, uključujući sljedeće načine: (a)
            prikazivanje kad ste ga objavili na web stranici; (b) u svrhu
            naplate opisane u članku 4; (c) odgovor na vaše upite; (d)
            izvršavanje ovlaštenih financijskih transakcija s vama; (e)
            identifikacija s uslugama trećih strana koje možemo koristiti za
            podršku korisnicima ili obradu plaćanja; (f) provjera vašeg
            identiteta i pomoć ako ste zaboravili bilo koje podatke o računu;
            (g) komunikacija s vama i pružanje informacija (bilo putem e-maila,
            pošte ili drugih sredstava) o našim proizvodima ili uslugama; (h)
            slanje marketinških ili proizvodno vezanih komunikacija od tvrtki u
            sklopu In Memoriam Bosna obitelji; (i) obavještavanje o promjenama u
            našim proizvodima i uslugama; (j) osiguravanje najefikasnijeg
            prikaza sadržaja s naše web stranice za vas i vaše računalo/uređaj;
            (k) omogućavanje sudjelovanja u interaktivnim značajkama naše web
            stranice, kada se odlučite za to; (l) omogućavanje sudjelovanja u
            natjecanjima koje organiziramo; (m) olakšavanje vašeg sudjelovanja u
            anketama u kojima dobrovoljno pružate lične informacije; (n)
            omogućavanje sudjelovanja u programima vjernosti ili nagrađivanja,
            ako to zatražite; (o) primanje i rješavanje povratnih informacija
            ili pritužbi od vas; (p) prijenos podataka vašim najbližima u
            slučaju vaše smrti; (q) u svrhu ispunjenja narudžbi našeg online
            dućana u skladu s In Memoriam Bosna Uvjetima; (r) zaštita naših
            pravnih interesa i ispunjenje naših regulatornih obveza (ako i
            koliko je to potrebno), i (s) u drugim okolnostima, uz vašu
            prethodnu suglasnost. Svi članovi i ostali s kojima komuniciramo
            imaju mogućnost odjave od primanja marketinških komunikacija od nas.
            Ako ne želite nastaviti primati elektronske marketinške komunikacije
            od nas i/ili odabranih trećih strana, trebate se odjaviti klikom na
            "odjava" vezu u bilo kojoj e-mail poruci koju vam pošaljemo.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        8. Treće strane
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Web stranica može sadržavati veze s web stranicama trećih strana,
            uključujući mreže naših vrijednih pružatelja usluga pridruženih
            partnera i/ili oglašivača. Ako pratite vezu prema bilo kojoj od tih
            web stranica, imajte na umu da one imaju vlastite politike
            privatnosti. Ako koristite našu web stranicu za povezivanje s drugom
            stranicom, bit ćete podložni uvjetima korištenja i politici
            privatnosti te stranice. Snažno preporučujemo da ih pregledate prije
            nego što otkrijete bilo koje svoje lične informacije na takvim
            stranicama. In Memoriam Bosna ne kontrolira i ne prihvaća nikakvu
            odgovornost za politiku privatnosti i korištenje ličnih informacija
            od strane bilo koje strane osim In Memoriam Bosna, uključujući bilo
            kojeg korisnika web stranice i operatore bilo koje web stranice na
            koju web stranica vodi. In Memoriam Bosna ne dijeli vaše lične
            informacije s tim web stranicama.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        9. Otkrivanje ličnih informacija
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Možemo otkriti vaše lične informacije bilo kojoj našoj povezanoj
            grupi tvrtki. Oni će ih koristiti samo u iste svrhe kao što mi
            možemo prema ovoj Politici privatnosti. Možemo pružiti lične
            informacije trećim stranama izvan naše grupe tvrtki u ograničene
            svrhe, poput pomoći u pružanju roba i usluga kupcima. Informacije
            koje pružamo trećim stranama ograničene su na informacije koje im je
            potrebno kako bi nam pomogle pružiti ili olakšati pružanje roba i
            usluga vama. Radimo s trećim stranama koje su dužne poštivati
            standarde privatnosti propisane Primjenjivim zakonima o privatnosti
            u postupanju s vašim ličnim informacijama i koristiti vaše lične
            informacije samo u svrhe koje smo im dali. Takve treće strane mogu
            uključivati, ali nisu ograničene na: (a) organizacije koje provode
            provjere kreditne sposobnosti, prevenciju prijevare i druge
            sigurnosne provjere; (b) kurirske i dostavne tvrtke (kada dogovaramo
            isporuku roba vama ili osobama kojima ste zatražili slanje dostava);
            (c) davatelje softvera trećih strana koji pohranjuju pojedinosti o
            korisničkim računima za nas ili pružaju druge IT usluge; (d)
            marketinške tvrtke koje smo angažirali za distribuciju materijala
            kojima su primatelji dali svoju suglasnost; i (e) također možemo
            otkriti vaše lične informacije trećim stranama izvan naše grupe
            tvrtki: (i) ako imamo vaš izričiti pristanak da to učinimo; (ii) ako
            se može razumno zaključiti iz okolnosti da pristajete na otkrivanje
            trećim stranama, osim ako je to zabranjeno Primjenjivim zakonima o
            privatnosti; (iii) ako In Memoriam Bosna proda, dodijeli ili na
            drugi način prenese dio ili cjelokupno svoje poslovanje, u kojem
            slučaju vaše lične informacije prikupljene In Memoriam Bosnaom putem
            vašeg korištenja usluge mogu biti među predmetima koji se prenose
            ili prodaju u mjeri u kojoj su relevantni za poslovanje In Memoriam
            Bosna; i (iv) ako smo dužni otkriti ili podijeliti vaše lične
            informacije radi poštovanja zakonske obveze ili radi provedbe naših
            uvjeta i odredbi ili za zaštitu prava, imovine ili sigurnosti In
            Memoriam Bosna, njegovog osoblja ili kupaca. To može uključivati
            razmjenu informacija s drugim tvrtkama i organizacijama u svrhu
            zaštite od prijevare i smanjenja kreditnog rizika.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        10. Sigurnost ličnih informacija
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Sigurnost vaših ličnih informacija važna je In Memoriam Bosnau.
            Stoga In Memoriam Bosna poduzima trgovinski razumne korake kako bi
            zaštitio vaše lične informacije od zloupotrebe, gubitka i
            neovlaštenog pristupa, izmjene i otkrivanja. Svaki korisnički račun
            ili stranica stvoreni na web stranici zaštićeni su korisničkim
            podacima koje korisnici pružaju radi privatnosti i sigurnosti.
            Međutim, ne možemo garantirati apsolutnu sigurnost tih informacija
            ili da će naši sustavi biti potpuno zaštićeni od presretanja trećih
            strana ili da su nepodložni virusima. Ne možemo i ne jamčimo da će
            informacije koje šaljete s vašeg računala nama putem interneta biti
            zaštićene nekom vrstom enkripcije (kodiranja softvera). S obzirom na
            to, ne možemo i ne jamčimo sigurnost ili privatnost vaših ličnih
            informacija, uključujući podatke o plaćanju i bankovnim računima.
            Vaše lične informacije šaljete nama na vlastitu odgovornost. Potpuno
            ste odgovorni za održavanje sigurnosti vaših lozinki i/ili podataka
            o računu. Ozbiljno shvaćamo sigurnost web stranica i kreditnih
            kartica i nastojimo osigurati sigurnu i sigurnu platformu putem koje
            se obavljaju transakcije.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        11. Dijeljenje informacija
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Ne prodajemo vaše lične informacije. In Memoriam Bosna ne prodaje i
            neće prodavati lične informacije rezidenta Kalifornije za koje
            stvarno zna da su mlađi od 16 godina. Zadržavamo pravo dijeliti
            informacije i podatke prikupljene u skladu s ovom Politikom
            privatnosti s drugim entitetima In Memoriam Bosna koji mogu biti
            registrirani izvan Bosne. In Memoriam Bosna također može
            prenijeti vaše lične informacije osobi koja se nalazi u inozemstvu
            kako bi nam pomogla u pružanju usluga. U tim okolnostima poduzet
            ćemo razumne korake kako bismo osigurali da informacije koje se
            prenose trećim stranama izvan Bosne neće biti držane, korištene
            ili otkrivene na način koji nije u skladu s australskim načelima
            privatnosti.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        12. Prava vezana uz podatke
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Možda imate pravo pristupa ličnim informacijama koje o vama
            posjedujemo. U skladu s Primjenjivim zakonima o privatnosti, možda
            imate pravo zatražiti i dobiti informacije o vašim ličnim
            informacijama koje obrađujemo, odakle smo dobili vaše informacije,
            poslovni ili komercijalni razlog za prikupljanje vaših informacija i
            treće strane s kojima se dijele vaše informacije. Također, u
            određenim okolnostima, možete zatražiti ispravak ili brisanje ličnih
            informacija koje se odnose na vas, ovisno o situaciji i primjenjivim
            zakonima. Sva naša obrada podataka podržava naše poslovanje. Ako smo
            vaše podatke obradili na temelju vaše suglasnosti, možete je povući
            ako želite. Stanovnici Kalifornije možda imaju sljedeća prava u vezi
            s njihovim ličnim informacijama, u određenim okolnostima: (a) Pravo
            znati kategorije i određene komade ličnih informacija koje smo
            prikupili o vama u posljednjih 12 mjeseci, izvore iz kojih su lične
            informacije prikupljene i poslovni razlog za prikupljanje takvih
            informacija; (b) Pravo znati jesmo li prodavali ili otkrivali vaše
            lične informacije, kome smo prodavali ili otkrivali vaše lične
            informacije i poslovni razlog za prodaju ili otkrivanje vaših ličnih
            informacija; (c) Pravo zatražiti kopiju određenih komada ličnih
            informacija koje smo prikupili o vama u posljednjih 12 mjeseci; (d)
            Pravo zatražiti da ne prodajemo vaše lične informacije; (e) Pravo
            zatražiti brisanje ličnih informacija koje smo prikupili od vas, u
            određenim okolnostima; i (f) Pravo da ne budete podvrgnuti
            diskriminaciji zbog korištenja svojih prava na privatnost. Možete se
            informirati o tim pravima ili ostvariti svoja prava tako što ćete
            nas kontaktirati kako je navedeno u članku 15 ove Politike
            privatnosti. Nakon primitka zahtjeva za ostvarivanje vaših prava,
            možda ćemo zatražiti dodatne informacije kako bismo provjerili vaš
            identitet. U mjeri u kojoj je moguće, koristit ćemo već dostupne
            informacije kako bismo provjerili vaš identitet. Sve informacije
            koje pružite u vezi s takvom provjerom bit će izbrisane što je prije
            moguće nakon vašeg zahtjeva. Možete odrediti ovlaštenog agenta koji
            će podnijeti zahtjev u vaše ime. Ako podnesete zahtjev putem
            ovlaštenog agenta, možemo zatražiti da ovlašteni agent dokaže da je
            ovlašten od vas za djelovanje u vaše ime i možemo i dalje
            zahtijevati da vi sami potvrdite svoj identitet u skladu s gore
            navedenim.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        13. Garancije i izjava
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Ova Politika privatnosti ne predstavlja ugovornu izjavu, obećanje,
            jamstvo ili garanciju od strane In Memoriam Bosna vama o načinu na
            koji će In Memoriam Bosna prikupljati, pohranjivati, koristiti,
            otkrivati, štititi ili na drugi način postupati s vašim ličnim
            informacijama. Neki su čimbenici izvan kontrole In Memoriam Bosna,
            poput zlonamjernih napada ili kriminalnih aktivnosti trećih strana.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        14. Pristup/ispravak ili ažuriranje vaših ličnih informacija
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Imate pravo pristupiti ličnim informacijama koje o vama posjedujemo
            i pravo zatražiti ispravak takvih ličnih informacija ako smatrate da
            su netočne, nepotpune ili zastarjele. Da biste dobili primjerak
            ličnih informacija koje posjedujemo o vama ili zatražili da In
            Memoriam Bosna ispravi vaše lične informacije, kontaktirajte nas
            kako je navedeno u članku 15 ove Politike privatnosti.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        15. Brige o privatnosti
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Ako: imate bilo kakve brige u vezi privatnosti vaših ličnih
            informacija ili mogućeg kršenja Primjenjivih zakona o privatnosti;
            želite dobiti primjerak ličnih informacija koje In Memoriam Bosna
            posjeduje o vama; želite podnijeti zahtjev za ispravak ličnih
            informacija koje In Memoriam Bosna posjeduje o vama, ako smatrate da
            su netočne, nepotpune ili zastarjele; ostvarujete bilo koje drugo
            pravo u vezi podataka. U e-mailu navedite sljedeće informacije: (a) vaše
            ime; (b) vašu adresu; (c) vaš broj telefona; (d) vašu e-mail adresu;
            (e) opis vaše zabrinutosti (ako je primjenjivo); (f) detalje o
            informacijama u pitanju kako bismo ih mogli pronaći (ako je
            primjenjivo). Zatim ćemo uložiti trgovinski razumne napore kako
            bismo brzo utvrdili postoji li problem ili akcija prema vašim
            zahtjevima i poduzeti potrebne ispravne mjere u razumnom roku. Ako
            želite podnijeti pritužbu ili smatrate da In Memoriam Bosna nije
            zadovoljavajuće odgovorio na vaše zabrinutosti, možete podnijeti
            službenu pritužbu nadležnom tijelu. U Bosni, možete poslati
            službenu pritužbu Uredu australskog povjerenika za informacije, 30
            dana nakon što ste kontaktirali In Memoriam Bosna.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        16. Brisanje, uklanjanje ili uništavanje ličnih informacija
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Možete zatražiti brisanje, uklanjanje, depersonalizaciju ili
            uništavanje vaših ličnih informacija od strane In Memoriam Bosna
            slanjem e-maila na: (a) Ako ste član In Memoriam Bosna: podrska@inmemoriam-bosna.ba (b) Ako ste član FutureMe: support@futureme.org.
            In Memoriam Bosna će uložiti trgovinski razumne napore kako bi
            ispuniti vaš zahtjev u razumnom roku i obavijestiti vas kada vaš
            zahtjev bude obrađen.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        17. U skladu s primjenjivim zakonima o privatnosti
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Bez obzira na bilo koju drugu odredbu ove Politike privatnosti: (a)
            zakoni primjenjive nadležnosti (uključujući primjenjive zakone o
            privatnosti) imaju prednost nad odredbama ove Politike privatnosti
            koje su u suprotnosti s tim zakonima; i (b) u mjeri u kojoj zakoni
            primjenjive nadležnosti, uključujući primjenjive zakone o
            privatnosti, nameću manje opterećujuće zahtjeve ili obveze In
            Memoriam Bosnau od ove Politike privatnosti, te odredbe bit će
            podrazumijevane kao dio ove Politike privatnosti i, u slučaju bilo
            kakve nesuglasnosti, te odredbe će prevladati nad izričitim
            odredbama ove Politike privatnosti.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        18. Odricanje
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Podložno zakonima primjenjive nadležnosti, bilo kakav propust ili
            odgađanje da od nas zahtijeva izvršenje ili ostvarivanje prava ili
            odredbi ove Politike neće predstavljati odricanje od tih prava ili
            odredbi, a ostvarivanje prava ili odredbi od strane nas neće
            spriječiti našu sposobnost da u budućnosti ostvarimo to ili bilo
            koje drugo pravo. Sva prava koja nisu izričito dodijeljena su
            rezervirana.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        19. Izmjene
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Zadržavamo pravo, po vlastitom nahođenju, da izmijenimo ovu Politiku
            privatnosti u bilo kojem trenutku. Trebali biste povremeno
            posjećivati ​​web stranice kako biste pregledali ovu Politiku
            privatnosti. Izmjene će stupiti na snagu odmah po obavijesti ili
            objavi na web stranici. Podložno zakonima primjenjive nadležnosti,
            vaše daljnje korištenje web stranica nakon takve obavijesti ili
            objave predstavljat će suglasnost da vas obavezuje ova Politika kao
            izmijenjena.
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        20. Sudski nadležnost
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Ova Politika privatnosti podliježe i treba se tumačiti prema zakonu
            koji je na snazi u primjenjivoj nadležnosti. Sudovi u primjenjivoj
            nadležnosti imat će isključivu nadležnost za rješavanje sporova u
            vezi s ovom Politikom privatnosti ili bilo kojim drugim pitanjem u
            vezi s radom web stranica.{" "}
          </li>
        </Typography>
      </ul>
      <Typography
        level="body1"
        component="h1"
        fontWeight="lg"
        sx={{
          color: "#3C5A77",
          mb: 2,
          fontSize: { xs: "1.25rem", sm: "1.5rem" },
        }}
      >
        {" "}
        21. Datum stupanja na snagu
      </Typography>
      <ul>
        <Typography
          level="h3"
          fontSize="md"
          sx={{
            color: "#3C5A77",
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          <li style={{ listStyleType: "none" }}>
            Ova Politika privatnosti stupa na snagu od 22. juna 2023. godine.
          </li>
        </Typography>
      </ul>
    </Container>
  );
}
export default Privacy;
