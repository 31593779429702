import { auth, db } from "../../../firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { setDoc, doc, getDoc, updateDoc} from "firebase/firestore";
import { format } from "date-fns";


export const loginService = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;
  
  // Dohvaćanje dateRegistration iz Firestore-a
  const userDocRef = doc(db, 'users', user.uid);
  const userDocSnap = await getDoc(userDocRef);
  const firestoreUserData = userDocSnap.data() || {};

  // Convert Firestore Timestamp to JavaScript Date object
  const dateRegistration = firestoreUserData.dateRegistration
    ? firestoreUserData.dateRegistration.toDate()
    : null;

  return {
    ...user,
    dateRegistration: dateRegistration,
  };
};

//LOGIN SERVICE FOR GOOGLE AND FACEBOOK
export const loginWithGoogleService = async () => {
  const provider = new GoogleAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  const user = userCredential.user;

  const userDocRef = doc(db, 'users', user.uid);
  const userDocSnap = await getDoc(userDocRef);

  // If user is not in Firestore, save them
  if (!userDocSnap.exists()) {
    const firstName = user.displayName.split(' ')[0];
    const lastName = user.displayName.split(' ')[1];
    const email = user.email;
    const photoURL = user.photoURL;
    const date = new Date();
    const dateRegistration = format(date, 'dd.MM.yyyy');
    
    const initialCredits = 1;

    await saveUserToFirestore(user, { firstName, lastName, email, photoURL, dateRegistration,credits: initialCredits });
  } else {
    const firestoreUserData = userDocSnap.data();
    const dateRegistration = firestoreUserData.dateRegistration
      ? firestoreUserData.dateRegistration.toDate()
      : null;
      const credits = firestoreUserData.credits;
 
    user.dateRegistration = dateRegistration;
    user.credits = credits;
    await setDoc(userDocRef, { dateRegistration, credits }, { merge: true });
  }

  return user;
};


/* export const loginWithFacebookService = async () => {
  const provider = new FacebookAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  return processUserCredential(userCredential);
}; */
export const loginWithFacebookService = async () => {
  const provider = new FacebookAuthProvider();
  try {
    const userCredential = await signInWithPopup(auth, provider);
    const user = userCredential.user;

    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);

    // If user is not in Firestore, save them
    if (!userDocSnap.exists()) {
      const firstName = user.displayName.split(' ')[0];
      const lastName = user.displayName.split(' ')[1];
      const email = user.email;
      const photoURL = user.photoURL;
      const date = new Date();
      const dateRegistration = format(date, 'dd.MM.yyyy');
      const initialCredits = 1;
      await saveUserToFirestore(user, { firstName, lastName, email, photoURL, dateRegistration, credits: initialCredits });
    } else {
      const firestoreUserData = userDocSnap.data();
      const dateRegistration = firestoreUserData.dateRegistration
        ? firestoreUserData.dateRegistration.toDate()
        : null;
      const credits = firestoreUserData.credits;
      
      user.dateRegistration = dateRegistration;
      user.credits = credits;
      await setDoc(userDocRef, { dateRegistration, credits }, { merge: true });
    }

    return user;
  } catch (error) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      /* alert('Izgleda da ste već registrirani s drugom metodom prijave (npr. email i lozinka ili Google) koristeći isti email. Molimo vas da se prijavite koristeći tu metodu.'); */
      // Please note that this will require user interaction.
      // For now, we just re-throw the error.
      throw error;
    } else {
      // Some other error happened.
      throw error;
    }
  }
}


/* const processUserCredential = async (userCredential) => {
  const user = userCredential.user;

  // Dohvaćanje dateRegistration iz Firestore-a
  const userDocRef = doc(db, 'users', user.displayName);
  const userDocSnap = await getDoc(userDocRef);
  const firestoreUserData = userDocSnap.data();

  return {
    ...user,
    dateRegistration: firestoreUserData?.dateRegistration,
  };
}; */

export const registerService = async ({ firstName, lastName, email, password, dateRegistration }) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;
 
  const displayName = `${firstName} ${lastName}`;
  await updateProfile(auth.currentUser, {
    displayName,
  });
/* 
  if (photoFile) {
    const storageRef = ref(storage, `userImages/${Date.now() + user.displayName}`);
    const metadata = {
      contentType: photoFile.type,
    };

    const uploadTask = uploadBytesResumable(storageRef, photoFile, metadata);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      (error) => {
        console.log(error.message);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await updateProfile(user, {
          photoURL: downloadURL,
        }); */

        // Convert JavaScript Date object to Firestore Timestamp
        /* const dateRegistrationTimestamp = Timestamp.fromDate(dateRegistration); */
       /*  const date = new Date();
        const dateRegistration = format(date, 'dd.MM.yyyy'); */
        await setDoc(doc(db, "users",  user.uid), {
          uid: user.uid,
          displayName,
          firstName,
          lastName,
          email,
          dateRegistration,
          credits: 1,
        });
      
    
  

  // Convert Firestore Timestamp to JavaScript Date object
  const dateRegistrationJS = dateRegistration.toDate();

  return {
    ...userCredential,
    user: {
      ...user,
      dateRegistration: dateRegistrationJS,
    },
  };
};





export const updateUserService = async (updatedData) => {
  const user = auth.currentUser;

  // Obratite pažnju da ovo pretpostavlja da `displayName` jedinstveno identifikuje korisnika.
  const userDocRef = doc(db, 'users', user.uid);

  // Convert JavaScript Date object to Firestore Timestamp if it's being updated
 /*  if(updatedData.dateRegistration){
    updatedData.dateRegistration = Timestamp.fromDate(updatedData.dateRegistration);
  } */

  await updateDoc(userDocRef, updatedData);

  // Returning updatedData for consistency, but you may want to fetch the updated data from Firestore instead.
  return updatedData;
};


export const logoutService = async () => {
    await signOut(auth);
};



//spremanje korisnika u firestore

const saveUserToFirestore = async (user, { firstName, lastName, email, photoURL, dateRegistration,credits }) => {
  const displayName = `${firstName} ${lastName}`;

  await setDoc(doc(db, "users", user.uid), {
    uid: user.uid,
    displayName,
    firstName,
    lastName,
    email,
    photoURL,
    dateRegistration,
    credits,
  });
};

/* export const subtractCredits = async (user, creditsToSubtract) => {
  const updatedCredits = user.credits - creditsToSubtract;
  const userDocRef = doc(db, 'users', user.uid);
  await updateDoc(userDocRef, { credits: updatedCredits });
};
 */
