import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { formatDate, formatDistanceToNowCustom } from "../../../../utils";




////------ASYNC THUNK-------------------------

export const getVideo = createAsyncThunk(
  "videoCards/getVideo",
  async (_, { getState, dispatch }) => {
    const response = await getDocs(collection(db, "videoCards"));
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);


export const getVideoByUser = createAsyncThunk(
  "videoCards/getVideoByUser",
  async (userId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "videoCards"), where("userId", "==", userId))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);
export const getVideoByPersonId = createAsyncThunk(
    "videoCards/getVideoByPersonId",
    async (personId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "videoCards"), where("personId", "==", personId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdAt: formatDistanceToNowCustom(data.createdAt),
          dateEvent: formatDate(data.dateEvent),  
         /* soundRequests: formatSoundRequests(data.soundRequests), */
         
        };
      });
      return result;
    
    }
  );

export const getVideoReactions = createAsyncThunk(
  "videoCard/getVideoReactions",
  async (videoId, { getState, dispatch }) => {
    const response = await getDoc(collection(db, "videoCard"), videoId);
    const result = response.data().reactions;
    return result;
  }
);
export const updateVideoReactions = createAsyncThunk(
  "videoCard/updateVideoReactions",
  async (payload, { getState, dispatch }) => {
    const { videoId, reaction } = payload;

    const cardRef = doc(db, "videoCard", videoId);
    const video = await getDoc(cardRef);
    const emotionValue = video.data().reactions[reaction];
    if (emotionValue) {
      await setDoc(cardRef, {
        reactions: {
          heart: "",
        },
      });
    } else

   {await updateDoc(cardRef, {
      reactions: {
        ...video.data().reactions,
        [reaction]: !emotionValue,
      },
    });}
  }
);

const initialState = {
  videoCards: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
 videoUpdated:false,
};

export const videoSlice = createSlice({
  name: "videoCards",
  initialState,
  reducers: {
    resetStateVideo: (state) => {
      return { ...initialState };
    },
    addVideo: (state, { payload }) => {
      state.videoCards.push(payload);
      state.videoUpdated=true;
    },
    editVideo: (state, { payload }) => {
      const videoIndex = state.videoCards.findIndex((video) => video.id === payload.id);
      if (videoIndex >= 0) {
        state.videoCards[videoIndex] = { ...state.videoCards[videoIndex], ...payload };
      } else {
        state.videoCards.push(payload);
      }
      state.videoUpdated=true;
    },
    deleteVideo: (state, { payload }) => {
      state.videoCards = state.videoCards.filter(video => video.id !== payload);
      state.videoUpdated=true;
    },
    

    addVideoReaction: (state, { payload }) => {
      /* console.log('addVideoReaction payload:', payload); // Add this line */
      const { videoId, reaction, userReactions } = payload;
        
      const existingVideo = state.videoCards.find((video) => video.id === videoId);
      if (existingVideo) {
        existingVideo.reactions[reaction]++;
        if (userReactions) {
          if (!existingVideo.userReactions) {
            existingVideo.userReactions = {};
          }
          existingVideo.userReactions[reaction] = userReactions;
        }
      }
    }},
  prepare(
    id,
    cardAuthor,
    userId,
    personId,
    cardDesc,
    dateEvent,
    createdAt,
    userReactions,
    video,
  ) {
    return {
      payload: {
        id,
        cardAuthor,
        userId,
        personId,
        reactions: {
          heart: 0,
      },userReactions: {
        heart: {},
      },
      video,
        cardDesc,
        dateEvent,
        createdAt,
      },
    };
  },
  extraReducers:(builder)=> {
    builder
    .addCase(getVideo.pending, (state) => {
        state.status = "loading";
      }
    )
    .addCase(getVideo.fulfilled,(state, { payload }) => {
        state.status = "succeeded";
        state.videoCards = payload;
        state.error = null;
        state.videoUpdated=false;
      }
    )
    .addCase(getVideo.rejected,(state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      }
    )
    .addCase(getVideoByPersonId.pending, (state) => {
      state.status = "loading";
    })
    .addCase(getVideoByPersonId.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.videoCards = payload;
      state.error = null;
      state.videoUpdated=false;
    })
    .addCase(getVideoByPersonId.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    })
    .addCase(getVideoByUser.pending, (state) => {
      state.status = "loading";
    })
    .addCase(getVideoByUser.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.videoCards = payload;
      state.error = null;
      state.videoUpdated=false;
    })
    .addCase(getVideoByUser.rejected, (state, { error }) => {
      state.status = "failed";
      state.error = error.message;
    }).addCase(resetStateVideo, (state) => {
      state.status = "idle";
       state.videoCards = [];
       state.error = null;
     });
   /* .addCase(updateReactions.fulfilled, (state, action) => {
      const { cardId, reaction } = action.payload;
      const existingPost = state.find((card) => card.id === cardId);
      if (existingPost) {
        existingPost.reactions[reaction] = !existingPost.reactions[reaction];
      }
    }
   ) */
  },
});

export const { addVideo,resetStateVideo, editVideo, deleteVideo,addVideoReaction } = videoSlice.actions;





export const getVideoStatus = (state) => state.videoCards.status;
export const getVideoError = (state) => state.videoCards.error;

const selectVideoCardsState = (state) => state.videoCards.videoCards;
export const videoUpdated=(state)=>state.videoCards.videoUpdated
// funkcija koja vraća isti personId
const selectPersonId = (_, personId) => personId;

// funkcija koja vraća isti videoId
const selectVideoId = (_, videoId) => videoId;

// funkcija koja vraća isti userId
const selectUserId = (_, userId) => userId;

// 
export const selectVideoByPersonId = createSelector(
  [selectVideoCardsState, selectPersonId],
  (videoCards, personId) => videoCards.filter((video) => video.personId === personId)
);

export const selectVideoByVideoId = createSelector(
  [selectVideoCardsState, selectVideoId],
  (videoCards, videoId) => videoCards.find((video) => video.id === videoId)
);

export const selectVideoByUserId = createSelector(
  [selectVideoCardsState, selectUserId],
  (videoCards, userId) => videoCards.filter((video) => video.userId === userId)
);
  export default videoSlice.reducer; 