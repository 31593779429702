import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, formatSoundRequests } from "../../../utils";

const initialState = {
  persons: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const getPersonsHeroes = createAsyncThunk(
    "persons/getPersonsHeroes",
    async (_,{getState,  dispatch }) => {
      const response = await getDocs(
        query(
          collection(db, "persons"),
          where("medRadnici", "==", true) ||
            where("policija", "==", true) ||
            where("ucitelji", "==", true) ||
            where("spasioci", "==", true) || 
            where("vatrogasci", "==", true) ||
            where("volonteri", "==", true)
        )
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          dateOfDeath: formatDate(data.dateOfDeath),
          createdAt: formatDate(data.createdAt),
          dateOfBirth: formatDate(data.dateOfBirth),
        /*   soundRequests: formatSoundRequests(data.soundRequests), */
         
        };
      });
      return result;
    
    }
  );

export const personsHeroeSlice = createSlice({
  name: "personsHeroes",
  initialState,
  reducers: { 
    resetStateHeroes: (state) => {
        return { ...initialState };
      
      }},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonsHeroes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPersonsHeroes.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.persons = payload;
      })
      .addCase(getPersonsHeroes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetStateHeroes }= personsHeroeSlice.actions
export const selectPersonByHeroes = (state) => 
  state.personsHeroes.persons
export const selectPersonByHeroe = (state) => 
  state.personsHeroes.persons.filter(
        (person) =>
          person.policija ||
          person.medRadnici ||
          person.vatrogasaci ||
          person.ucitelji ||
          person.spasioci ||
          person.volonteri
      );

  
export const getPersonsHeroesStatus = (state) => state.personsHeroes.status;
export const getPersonsHeroesError = (state) => state.personsHeroes.error;

export default personsHeroeSlice.reducer;