import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, formatSoundRequests } from "../../../utils";

const initialState = {
  persons: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const getPersonsSehidi = createAsyncThunk(
  "personsRvi/getPersonsSehidi",
  async (_, thunkAPI) => {
    const response = await getDocs(
      query(collection(db, "persons"), where("sehid", "==", true))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateOfDeath: formatDate(data.dateOfDeath),
        createdAt: formatDate(data.createdAt),
        dateOfBirth: formatDate(data.dateOfBirth),
        /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);
export const resetPersonsSehidi = createAction("personsSehidi/reset");

export const personsSehidiSlice = createSlice({
  name: "personsSehidi",
  initialState,
  reducers: { resetStateSehidi: (state) => {
     
    return { ...initialState };
  
  }},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonsSehidi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPersonsSehidi.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.persons = payload;
      })
      .addCase(getPersonsSehidi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }).addCase(resetStateSehidi, (state) => {
        state.status = "idle";
         state.persons = [];
         state.error = null;
       });
  },
});
export const { resetStateSehidi } = personsSehidiSlice.actions;
export const selectPersonBySehidi = (state) => state.personsSehidi.persons;
export const getPersonsSehidiStatus = (state) => state.personsSehidi.status;
export const getPersonsSehidiError = (state) => state.personsSehidi.error;

export default personsSehidiSlice.reducer;