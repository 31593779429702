import {
    createAsyncThunk,
    createSelector,
    createSlice,
  } from "@reduxjs/toolkit";
  import { collection, getDocs, query, where } from "firebase/firestore";
  import { db } from "../../../../firebase";
import { formatDistanceToNowCustom, formatDistanceToNowCustomComment } from "../../../../utils";
  
  const initialState = {
    comments: [],
  };
  
  export const getComments = createAsyncThunk(
    "comments/getComments",
    async (_, { getState }) => {
      const response = await getDocs(
        query(collection(db, "comments")));
      
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
      
        };
      });
      return result;
    
    }
  );
  export const getCommentsImageByPersonId = createAsyncThunk(
    "textComments/getCommentsImageByPersonId",
    async (personId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "comments"), where("personId", "==", personId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );
  export const getCommentsByCardId = createAsyncThunk(
    "comments/getCommentsByCardId",
    async (cardId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "comments"), where("cardId", "==", cardId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
         
        };
      });
      return result;
    
    }
  );
  
  export const getImageCommentsByUser = createAsyncThunk(
    "comments/getCommentsByUser",
    async (userId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "comments"), where("userId", "==", userId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: data.createdComAt ? formatDistanceToNowCustom(data.createdComAt.toDate()) : "",
       
         
        };
      });
      return result;
    
    }
  );
  export const commentSlice = createSlice({
    name: "comments",
    initialState,
    reducers: {
      resetStateCommentImage: (state) => {
        return { ...initialState };
      },
      addComment: (state, { payload }) => {
        state.comments.push(payload);
      },
      addReaction: (state, { payload }) => {
        console.log('addReaction payload:', payload); 
        const { commentId, reaction, userReactions } = payload;
          
        const existingComment = state.comments.find((comment) => comment.id === commentId);
        if (existingComment) {
          existingComment.reactions[reaction]++;
          if (userReactions) {
            if (!existingComment.userReactions) {
              existingComment.userReactions = {};
            }
            existingComment.userReactions[reaction] = userReactions;
          }
        }
      }
    },
    
    extraReducers: (builder) => {
      builder.addCase(getComments.fulfilled, (state, { payload }) => {
        state.comments = payload;
      })
      .addCase(getCommentsByCardId.fulfilled, (state, {payload})=>{
        state.comments = payload;
      }).addCase(getCommentsImageByPersonId.fulfilled, (state, {payload})=>{
        state.comments = payload;
      })

    },
  });
  
  export const { addComment,addReaction,resetStateCommentImage } = commentSlice.actions;
  
  export default commentSlice.reducer;
 
  export const selectImageCommentByPersonId = createSelector(
    [(state) => state.imageComments.comments, (state, personId) => personId],
    (comments, personId) => comments.filter((comment) => comment.personId === personId)
  );
  export const selectCommentByCardId = createSelector(
    [(state) => state.imageComments.comments, (state, cardId) => cardId],
    (comments, cardId) => comments.filter((comment) => comment.cardId === cardId)
  );
  
  export const selectCommentById = createSelector(
    [(state) => state.imageComments.comments, (state, commentId) => commentId],
    (comments, commentId) => comments.find((comment) => comment.id === commentId)
  );
  
  export const selectCommentImageByUserId = createSelector(
    [(state) => state.imageComments.comments, (state, userId) => userId],
    (comments, userId) => comments.filter((comment) => comment.userId === userId)
  );