import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";




const Loading = ({value}) => {
  return (
    <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999,
    }}
  >
    <CircularProgress
      variant={value !== undefined ? "determinate" : "indeterminate"}
      value={value}
      sx={{ color: "#ffffff" }}
    />
   <Typography
  variant="h6"
  sx={{
    color: "#ffffff",
    marginTop: 2,
  }}
>
  {value !== undefined ? `${value.toFixed(0)}%` : "Loading"}
</Typography>

  </Box>
  )
};

export default Loading;
