import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLoginStatus } from "../redux/features/auth/authSlice";
import { doc, onSnapshot} from 'firebase/firestore';
export const useAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Nakon što se korisnik prijavi, prati promjene na njegovom dokumentu
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeSnapshot = onSnapshot(userDocRef, (doc) => {
          const firestoreUserData = doc.data() || {};
          
          const u1 = user.email.substring(0, user.email.indexOf("@"));
          const uName = u1.charAt(0).toUpperCase() + u1.slice(1);

          const userData = {
            uid: user.uid,
            email: user.email,
            displayName: uName,
            photoURL: user.photoURL,
            credits: firestoreUserData.credits, 
            dateRegistration: firestoreUserData.dateRegistration,
          };
    
          dispatch(
            setLoginStatus({
              loginStatus: true,
              loading: false,
              userData: userData,
            })
          );
        });

        // Kada se korisnik odjavi, prekida se praćenje promjena na njegovom dokumentu
        return () => unsubscribeSnapshot();
      } else {
        dispatch(
          setLoginStatus({ loginStatus: false, userData: "", loading: false })
        );
      }
    });

    return () => {
      if (unsubscribeAuth) {
        unsubscribeAuth();
      }
    };
  }, [dispatch]);
};
