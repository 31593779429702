import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Box, List, Avatar } from "@mui/material";
import { Button, IconButton, Input, Typography } from "@mui/joy";

import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  getPersonsSearchError,
  getPersonsSearchStatus,
  resetStateSearch,
  searchPersons,
} from "../../redux/features/persons/personsSearchSlice";
import { Loading } from "../../utils";
import algoliasearch from "algoliasearch";
import {
  Configure,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
} from "react-instantsearch-dom";

const searchClient = algoliasearch(
  "791GDSMUTE",
  "638dfd9f610648d58dec39c82c71b89b"
);
const index = searchClient.initIndex("persons");
const SearchBar = ({ showSearch, setShowSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      search(searchTerm);
    }
  };

  const search = async (term) => {
    const { hits } = await index.search(term);
    setSearchResults(hits);
    setShowResults(true);
  };

  const handleFocus = () => {
    setShowResults(true);
  };

  const handleReset = () => {
    setSearchTerm("");
    setShowResults(false);
  };

  const handleBlur = () => {
    if (searchTerm) {
      setTimeout(() => setShowResults(false), 200);
    }
  };

  const handlePersonClick = (path) => {
    setSearchTerm("");
    navigate(path);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="persons">
      <Box sx={{ display: "flex", width: "100%" }}>
        {" "}
        <Input
          placeholder="Search for anything..."
          variant="outlined"
          size="md"
          value={searchTerm}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          endDecorator={
            <IconButton variant="plain" onClick={()=>setShowSearch(false)}>
              <CloseIcon />
            </IconButton>
          }
          sx={{
            "--Input-radius": "40px",
            "--Input-paddingInline": "12px",
            width: "100%",
            borderRadius: "md",
            color: "#0C4DE",
            bgcolor: "transparent",
          }}
        />{" "}
      </Box>

      {searchTerm && showResults && (
        <Box
          sx={{
            ml: "1rem",
            position: "absolute",
            top: "80%",
            zIndex: 1,
            borderRadius: "5px",
            background: "linear-gradient(135deg, #1B263B, #2F5370)",
          }}
        >
          <List>
            <Hits hitComponent={(props) => <Hit {...props} handlePersonClick={handlePersonClick} />} />
          </List>
        </Box>
      )}
    </InstantSearch>
  );
};

function Hit({ hit, handlePersonClick }) {
  return (
    <Button
      variant="plain"
      onClick={() => handlePersonClick(hit.path)}
      sx={{
        width: "fit-content",
        display: "flex",
        gap: "1",
      }}
    >
      <Avatar src={hit?.personURL} alt={hit.id} />
      <Typography level="body2" sx={{ paddingLeft: "8px" }}>
        {" "}
        <Highlight attribute="firstName" hit={hit} />
      </Typography>
      <Typography level="body2" sx={{ paddingLeft: "8px" }}>
        {" "}
        <Highlight attribute="lastName" hit={hit} />
      </Typography>
    </Button>
  );
}

export default SearchBar;