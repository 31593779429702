import React, { lazy} from 'react';
import { Route, Routes } from 'react-router-dom';
import Privacy from '../pages/informations/Privacy';
import { PricingPage } from '../pages/pricing/PricingPage';


const AboutUs = lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(import('../pages/informations/AboutUs'));
        }, 300);
      })
  );
  const Faq = lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(import('../pages/informations/Faq'));
        }, 300);
      })
  );
  const ContactPage = lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(import('../pages/informations/Contact.js'));
        }, 300);
      })
  ); 
function InfoRoutes  ()  {
  return (
    <Routes>
     <Route path="o-nama" element={<AboutUs />} />
    <Route path="faq" element={<Faq/>} />
    <Route path="kontakt" element={<ContactPage/>}/>
    <Route path="krediti" element={<PricingPage/>}/>
    <Route path="zastita-privatnosti" element={<Privacy/>}/>
  </Routes>
  
  );
};

export default InfoRoutes;