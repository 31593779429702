import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, formatSoundRequests } from "../../../utils";

const initialState = {
  persons: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const getPersonsDemBorci = createAsyncThunk(
  "personsRvi/getPersonsDemBorci",
  async (getState, dispatch) => {
    const response = await getDocs(
      query(collection(db, "persons"), where("demBorac", "==", true))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateOfDeath: formatDate(data.dateOfDeath),
        createdAt: formatDate(data.createdAt),
        dateOfBirth: formatDate(data.dateOfBirth),
       /*  soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);

export const personsDemBorcilice = createSlice({
  name: "personsDemBorci",
  initialState,
  reducers: {resetStateDemBorci: (state) => {
     
    return { ...initialState };
  
  }},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonsDemBorci.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPersonsDemBorci.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.persons = payload;
      })
      .addCase(getPersonsDemBorci.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const {resetStateDemBorci} = personsDemBorcilice.actions
export const selectPersonByDemBorci = (state) => state.personsDemBorci.persons;
export const getPersonsDemBorciStatus = (state) => state.personsDemBorci.status;
export const getPersonsDemBorciError = (state) => state.personsDemBorci.error;

export default personsDemBorcilice.reducer;