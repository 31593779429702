import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// MATERIAL UI AND ICONS
import { GiCoins } from "react-icons/gi";
import { BiSearchAlt } from "react-icons/bi";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
//REDUX SLICE AND SERVICE
import { logout, selectUserData } from "../../redux/features/auth";
//COMPONENTS AND UTILS
import { AlgoliaSearch } from "../../components/Search";
import Loading from "../../utils/Loading";
import DropdownMenu from "./DropDownMenu";
import ProfileMenu from "./ProfileMenu";
import { useHeaderState, useHeaderHandlers } from "./headerUtils";
import logo from "../../assets/log3o.png";
import { Badge } from "@mui/joy";




export default function Header() {

  const user = useSelector(selectUserData);
  const credits = user?.credits;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = React.useState(false);
  const loginStatus = useSelector((state) => state.users.loginStatus);
  const loading = useSelector((state) => state.users.loading);

  const logOut = () => {
    dispatch(logout());
    navigate("/");
  };

  const {
    setMainMenuAnchorEl,
    setMobileMenuIconAnchorEl,
    anchorEl,
    setAnchorEl,
    setMobileMoreAnchorEl,
    mainMenuAnchorEl,
    menuMainOpen,
    menuProfilOpen,
    setMenuMainOpen,
    setMenuMainIconOpen,
    setMenuProfilOpen,
    setMenuProfilMobOpen,
  } = useHeaderState();

  const {
    handleMainMenuOpen,
    handleMainMenuClose,
    handleProfileMenuOpen,
    handleMenuClose,
  } = useHeaderHandlers({
    setMainMenuAnchorEl,
    setMobileMenuIconAnchorEl,
    setAnchorEl,
    setMobileMoreAnchorEl,
    setMenuMainOpen,
    setMenuMainIconOpen,
    setMenuProfilOpen,
    setMenuProfilMobOpen,
  });

  //padajuci meni za glani meni
  const mainMenu = (
    <DropdownMenu
      anchorEl={mainMenuAnchorEl}
      open={Boolean(mainMenuAnchorEl)}
      handleClose={handleMainMenuClose}
      navigate={navigate}
    />
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <ProfileMenu
      anchorEl={anchorEl}
      open={menuProfilOpen}
      handleClose={handleMenuClose}
      navigate={navigate}
      logOut={logOut}
      user={user}
      menuId={menuId}
    />
  );

  // funkcija za otvaranje pretrazivaca
  const handleClick = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        width: "90%",
        zIndex: 9999,

        display: "flex",
      }}
    >
      <Sheet
        variant="solid"
        invertedColors
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          p: 2,
          mt: 0,
          mb: 0,
          borderRadius: { xs: "0 0 0 0" },
          minWidth: "100vw",
          background: "linear-gradient( 135deg, #1B263B, #2F5370)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: { xs: "flex" } }}>
            {" "}
            <IconButton
              size="sm"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              aria-controls={menuId}
              sx={{ mr: 2 }}
              onClick={menuMainOpen ? handleMainMenuClose : handleMainMenuOpen}
            >
              {menuMainOpen ? <CloseIcon size="sm" /> : <MenuIcon />}
            </IconButton>
          </Box>

          <Box
  onClick={() => navigate("/")}
  sx={{
    paddingLeft: "0rem",
    position: "relative",
    color: "#B0C4DE",
   
  }}
><Box sx={{paddingTop:"0.5rem",paddingLeft: "-1rem",display: { xs: "felx", sm: "none", display:"flex",
    position: "relative", },}}>
<img
    src={logo}
    alt="logo"
    style={{
      width: 32,
      height: 32,

     
    }}
  />
   <Typography
    variant="plain"
    level="h6"
    noWrap
    sx={{ color: "#E1ECF8", display: { xs: "block", sm: "none" }, }}
  >
    In Memoriam
  </Typography> 
  </Box>
  
  <Box
            onClick={() => navigate("/")}
            sx={{
              paddingLeft: "7.5rem",
              display: { xs: "none", sm: "block" },
              position: "relative",
              color: "#B0C4DE",
              "&::before": {
                content: '"Bosna i Hercegovina"',
                position: "absolute",
                left: "122px",
                top: "-7px",
              },
            }}
          >
    <Typography variant="plain" level="h2" noWrap sx={{ color: "#E1ECF8" }}>
      InMemoriam
    </Typography>
    <img
    src={logo}
    alt="logo"
    style={{
      width: 60,
      height: 60,
      position: "absolute",
      left: "4rem",
      top: "-0.5rem",
    }}
  />
  </Box>

  
</Box>

          <Box sx={{ display: "flex" }}>
            {loading ? (
              <Loading />
            ) : !loginStatus ? (
              <>
                {" "}
                {!showSearch && (
                  <IconButton
                    variant="plain"
                    sx={{
                      borderRadius: "md",
                      marginTop: "0.2rem",
                      p: 1,
                      ml: 2,
                      color: "#B0C4DE",
                      bgcolor: "transparent",
                    }}
                  >
                    <BiSearchAlt size={25} onClick={handleClick} />
                  </IconButton>
                )}
                <IconButton
                  sx={{
                    borderRadius: "md",
                    mr: "0.5rem",
                    color: "#B0C4DE",
                    bgcolor: "transparent",
                  }}
                  onClick={() => navigate("/auth/prijava")}
                  variant="plain"
                >
                  Prijava
                </IconButton>
              </>
            ) : (
              <Box sx={{ display: "flex", paddingRight: "0rem" }} gap={2}>
                {!showSearch && (
                  <IconButton
                    variant="plain"
                    sx={{
                      borderRadius: "md",
                      marginTop: "0.2rem",

                      color: "#B0C4DE",
                      bgcolor: "transparent",
                    }}
                  >
                    <BiSearchAlt size={30} onClick={handleClick} />
                  </IconButton>
                )}

                <IconButton
                  variant="plain"
              
                  sx={{
                    borderRadius: "md",

                    color: "#FFEB3B",
                    bgcolor: "transparent",
                  }}
                  onClick={() => {
                    
                    navigate("info/krediti");
                  }}
                  size="sm"
                  aria-label="show 1 new notifications"
                  color="inherit"
                >
                  <Badge
                    badgeContent={credits}
                    max={9999}
                    badgeInset="0%"
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#4A7097",
                        backgroundColor: "#F2F4F8",
                      },
                    }}
                  >
                    <GiCoins size={25} />
                  </Badge>
                </IconButton>
                <IconButton
                  variant="plain"
                  color="inherit"
                  aria-controls={menuId}
                  onClick={
                    menuProfilOpen ? handleMenuClose : handleProfileMenuOpen
                  }
                  sx={{ borderRadius: "lg" }}
                >
                  {menuProfilOpen ? (
                    <CloseIcon size="sm" />
                  ) : (
                    <Avatar size="sm" src={user.photoURL} />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>{" "}
        {showSearch ? (
          <Box sx={{ paddingLeft: { xs: "0", sm: "3.5rem" } }}>
            <AlgoliaSearch
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
          </Box>
        ) : null}
      </Sheet>
      {renderMenu}
      {mainMenu}
    </Box>
  );
}
