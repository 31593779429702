import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, formatSoundRequests } from "../../../utils";

export const searchPersons = createAsyncThunk(
  "persons/search",
  async ({firstName,lastName },{ dispatch }) => {
    const response = await getDocs(
      query(collection(db, "persons"), where("firstName", "==", firstName), where("lastName", "==", lastName))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateOfDeath: formatDate(data.dateOfDeath),
        createdAt: formatDate(data.createdAt),
        dateOfBirth: formatDate(data.dateOfBirth),
    /*     soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  }
);

const initialState = {
    person: [],
    status: "idle",
    error: null,
    searchResults: [],  // dodajte ovo polje
  };
  
export const personsSearchSlice = createSlice({
  name: "searchPerson",
  initialState,
  reducers: {
    resetStateSearch: (state) => {
      return { ...initialState };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPersons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchPersons.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.searchResults= payload; 
      })
      .addCase(searchPersons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { resetStateSearch }= personsSearchSlice.actions;

const selectPersonsIdState = (state) => state.searchPerson;

// Memoizacija selektora
export const selectSearchPerson = createSelector(
  [selectPersonsIdState, (_, personId) => personId],
  (personsIdState, personId) =>
    personsIdState.person.find((person) => person.personId === personId)
);

export const getPersonsSearchStatus = (state) => state.searchPerson.status;
export const getPersonsSearchError = (state) => state.searchPerson.error;

export default personsSearchSlice.reducer;
