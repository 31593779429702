import React from "react";
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate('/');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#F2F4F8',
        color: '#3C5A77'
      }}
    >
      <Typography
        variant="h1"
        component="div"
        sx={{ fontWeight: 'bold', color: '#1E3D59' }}
      >
        404
      </Typography>
      <Typography
        variant="h5"
        component="div"
        sx={{ marginBottom: '20px' }}
      >
        Oops! Page not found.
      </Typography>
      <Button
        variant="contained"
        onClick={handleBackHome}
        sx={{
          backgroundColor: '#4A7097',
          color: '#F2F4F8',
          '&:hover': {
            backgroundColor: '#2F5370'
          }
        }}
      >
        Back Home
      </Button>
    </Box>
  )
};

export default NotFoundPage;
