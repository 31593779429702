import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  getDocs,
  collection,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, } from "../../../utils";


export const getPersons = createAsyncThunk(
  "persons/getPersons",
  async (getState, dispatch) => {
    const response = await getDocs(collection(db, "persons"));
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateOfDeath: formatDate(data.dateOfDeath),
        createdAt: formatDate(data.createdAt),
        dateOfBirth: formatDate(data.dateOfBirth),
        /* soundRequests: formatSoundRequests(data.soundRequests),
        timeReaction:formatSoundRequests(data.timeReaction) */
      };
    });
    return result;
  
  }
);
export const getPersonsLast10 = createAsyncThunk(
  "persons/getPersonsLast10",
  async (getState, dispatch) => {
    const response = await getDocs(collection(db, "persons"));
    let result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        dateOfDeath: formatDate(data.dateOfDeath),
        createdAt: formatDate(data.createdAt),
        dateOfBirth: formatDate(data.dateOfBirth),
        /* soundRequests: formatSoundRequests(data.soundRequests),
        timeReaction:formatSoundRequests(data.timeReaction) */
      };
    });
    
    // Sortiranje rezultata prema polju createdAt u silaznom redoslijedu
    result = result.sort((a, b) => b.createdAt - a.createdAt);
    
    // Odabir samo prvih 10 rezultata
    result = result.slice(0, 5);
    
    return result;
  }
);


const initialState = {
  persons: [],
  searchResults: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  personIdUpdated:false,
};


export const personsSlice = createSlice({
  name: "persons",
  initialState,
  reducers: {
    addPerson: {
      reducer: (state, { payload }) => {
        state.persons.push(payload);
        
      },
    },
    resetStateAll: (state) => {
      return { ...initialState };
    },
    addReaction: (state, { payload }) => {
      console.log("addReaction payload:", payload);
      const { personId, reaction, soundRequests } = payload;
    
      const existingPersonIndex = state.persons.findIndex(
        (person) => person.id === personId
      );
      
      if (existingPersonIndex > -1) {
        const existingPerson = state.persons[existingPersonIndex];
    
        const updatedReactions = {
          ...existingPerson.reactions,
          [reaction]: (existingPerson.reactions[reaction] || 0) + 1
        };
    
        let updatedSoundRequests = existingPerson.soundRequests;
        if (soundRequests) {
          updatedSoundRequests = {
            ...existingPerson.soundRequests,
            [reaction]: soundRequests
          };
        }
    
        // Ažuriranje postojeće osobe s novim objektima za reactions i soundRequests
        state.persons[existingPersonIndex] = {
          ...existingPerson,
          reactions: updatedReactions,
          soundRequests: updatedSoundRequests
        };
      }
    },
    
    editPersons: (state, { payload }) => {
      const personIndex = state.persons.findIndex(
        (person) => person.id === payload.id
      );
      if (personIndex >= 0) {
        state.persons[personIndex] = {
          ...state.persons[personIndex],
          ...payload,
        };
      } else {
        state.persons.push(payload);
      }
   
    },

    editPerson: (state, { payload }) => {
      state.persons[payload.id] = payload;

    },

    deletePerson: (state, { payload }) => {
      state.persons = state.persons.filter(
        (person) => person.id !== payload.id
      );
    
    },

    addOrUpdatePersonPicture: (state, { payload }) => {
      const personIndex = state.persons.findIndex(
        (person) => person.id === payload.id
      );
      if (personIndex >= 0) {
        state.persons[personIndex] = {
          ...state.persons[personIndex],
          ...payload,
        };
      } else {
        state.persons.push(payload);
      }
      state.personIdUpdated=true;
    },

    deletePersonPicture: (state, { payload }) => {
      const { id } = payload;
      const personIndex = state.persons.findIndex((person) => person.id === id);
      delete state.persons[personIndex].personURL;
    
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(getPersons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPersons.fulfilled, (state, { payload }) => {
        if (!state.alreadyFetched) {
          state.status = "succeeded";
          state.persons = payload;
          state.alreadyFetched = true;
        /*   state.personIdUpdated=false; */
        }
      })
      .addCase(getPersons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }).addCase(getPersonsLast10.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPersonsLast10.fulfilled, (state, { payload }) => {
        if (!state.alreadyFetched) {
          state.status = "succeeded";
          state.persons = payload;
          state.alreadyFetched = true;
         /*  state.personIdUpdated=false; */
        }
      })
      .addCase(getPersonsLast10.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      
  },
});
export const {
  addPerson,
  editPerson,
  deletePersonPicture,
  editPersons,
  addOrUpdatePersonPicture,
  addReaction,
  resetStateAll,
} = personsSlice.actions;

export const selectAllPerson = (state) => state.persons.persons;
export const getPersonStatus = (state) => state.persons.status;
export const getPersonError = (state) => state.persons.error;
export default personsSlice.reducer;
export const personIdUpdated=(state)=> state.persons.personIdUpdated;
export const selectPersons = (state) => state.persons.persons;




export const getReactions = createAsyncThunk(
  "persons/getReactions",
  async (personId, { getState, dispatch }) => {
    const response = await getDoc(collection(db, "persons"), personId);
    const result = response.data().reactions;
    return result;
  }
);
