
import { format, formatDistance, parse} from "date-fns";
import { bs } from "date-fns/locale";
import { Timestamp } from "firebase/firestore";


export const formatSoundRequests = (soundRequests) => {
  const formatted = {...soundRequests}; // create a copy of the object
  
  // Iterate over all reactions
  for (let reaction in soundRequests) {
    // Iterate over all requests for a reaction
    formatted[reaction] = soundRequests[reaction].map(request => ({
      ...request,
      timestamp: formatDate(request.timestamp),
      timeReaction: formatDate(request.timeReaction)
    }));
  }

  return formatted;
}


export const formatDate = (timestamp, dateFormat = "dd.MM.yyyy") => {
  if (!timestamp || !(timestamp instanceof Timestamp)) {
    console.error("Invalid date value:", timestamp);
    return "";
  }
  
  const date = timestamp.toDate();
  return format(date, dateFormat,{ locale: bs });
};


export const formatDat = (timestamp, dateFormat = "dd.MM.yyyy") => {
  if (!timestamp) {
    console.error("Invalid date value:", timestamp);
    return "";
  }
  
  let date;

  if (timestamp instanceof Timestamp) {
    date = timestamp.toDate();
  } else {
    date = new Date(timestamp);
  }

  return format(date, dateFormat,{ locale: bs });
};


export const formatDistanceToNowCustomComment = (dateString) => {
  if (!dateString || typeof dateString !== "string") {
    /*  console.error("Invalid date value:", dateString);  */
    return "";
  }

  const date = parse(dateString, "dd.MM.yyyy", new Date());
  
  return formatDistance(date, new Date(), { addSuffix: true, locale: bs });
};
  
export const formatDistanceToNowCustom = (timestamp) => {
  if (!timestamp || !(timestamp instanceof Timestamp)) {
    /*  console.error("Invalid date value:", timestamp);  */
    return "";
  }
  
  const date = timestamp.toDate();
 
  return formatDistance( date,new Date(), { addSuffix: true, locale: bs });
};

export const formatDateYear = (dateStr) => {
  const parts = dateStr.split(".");
  return parts[2]; 
};




  export const formatDateYea = (input, dateFormat = "yyyy") => {
    if (!input) {
      console.error("Invalid date value:", input);
      return "";
    }
    let date;
  
    
    if (typeof input === "object" && input.hasOwnProperty("seconds")) {
      const dateSec = input.seconds * 1000;
      date = new Date(dateSec);
    } else if (typeof input === "number") {
      date = new Date(input);
    } else {
      console.error("Invalid date value:", input);
      return "";
    }
  
    return format(date, dateFormat, { locale: bs });
  };
  



  const timestampToDate = (timestamp) => {
    return timestamp.toDate();
  };
  
  // Funkcija koja vraća razliku između dva datuma u ljudski čitljivom obliku
  const formatDateDistance = (date) => {
    return formatDistance(new Date(), date, { addSuffix: true, locale: bs });
  };