import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from '@mui/icons-material/Facebook';
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, loginWithFacebook, loginWithGoogle} from "../../redux/features/auth";
import { Loading } from "../../utils";
import { useEffect } from "react";
import { auth } from "../../firebase";

export default function Login() {
 

  const dispatch = useDispatch();
  const user = auth.currentUser ? auth.currentUser : null; 
  const [errorLogin, setErrorLogin] = useState(false);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [formReg, setFormReg] = useState({ email: "", password: "" });
  const { email, password } = formReg;
  const navigate = useNavigate();
  
  ////--------------------------

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setFormReg({ ...formReg, [name]: value });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    setLoginIsLoading(true);
    try {
      await dispatch(login({ email, password }));
      setTimeout(() => {
        navigate(`/profile/${user?.uid}`);
      }, 100);
      toast.success("Uspješno ste se prijavili");
    } catch (e) {
      setLoginIsLoading(false);
      setErrorLogin(e.message);
    }
  };

///Login with Google
const loginGoogle = async(e) => {
  e.preventDefault();
  setLoginIsLoading(true);
  try {
    await dispatch(loginWithGoogle()) // čeka na loginWithGoogle da se završi
    setTimeout(() => {
      navigate(`/profile/${user?.uid}`);
    }, 100);
    toast.success("Uspješno ste se prijavili");
  } catch (e) {
    setLoginIsLoading(false);
    setErrorLogin(e.message);
  }
};



//Login with facebook
const loginFacebook = async (e) => {
  e.preventDefault();
  setLoginIsLoading(true);
  try {
    await dispatch(loginWithFacebook());
    toast.success("Uspješno ste se prijavili");
  } catch (e) {
    toast.error("Izgleda da ste već registrirani s drugom metodom prijave (npr. email i lozinka ili Google) koristeći isti email. Molimo vas da se prijavite koristeći tu metodu.");
    setLoginIsLoading(false);
    navigate("/")
    setErrorLogin(e.message);
    
  }
};

useEffect(() => {
  if(user && user.uid) {
    navigate(`/profile/${user.uid}`, { replace: true });
  }
}, [user, navigate]);
  return (
    <>{loginIsLoading && <Loading />}
      <Grid
        container
        component="main"
        sx={{ height: "100vh", backgroundColor: "#F2F4F8",pt:'2rem',pb:'0rem' }}
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{zIndex:999,mb:"0.5rem"}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#4A7097" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: "#1E3D59" }}>
              Prijava 
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={loginUser}
              sx={{ mt: 1 }}
            >
              <TextField
              size="small"
              fullWidth={true}
              variant="outlined"
              sx={{
                '& .MuiInputLabel-root': {
                  color: '#4A7097',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#4A7097',
                  },
                  '&:hover fieldset': {
                    borderColor: '#4A7097',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#4A7097',
                  },
                },
              }}
                margin="normal"
                aria-invalid={errorLogin ? "true" : null}
                
                id="email"
                label="Email Adresa"
                name="email"
                value={email}
                autoComplete="email"
                autoFocus
                onChange={handleLoginChange}
                required
              />
              <TextField
              size="small"
              fullWidth={true}
              variant="outlined"
              sx={{
                '& .MuiInputLabel-root': {
                  color: '#4A7097',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#4A7097',
                  },
                  '&:hover fieldset': {
                    borderColor: '#4A7097',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#4A7097',
                  },
                },
              }}
                margin="normal"
                aria-invalid={errorLogin ? "true" : null}
                required
              
                name="password"
                label="Lozinka"
                type="password"
                id="password"
                value={password}
                autoComplete="current-password"
                onChange={handleLoginChange}
              />
            {/*   <FormControlLabel
                control={
                  <Checkbox value="remember" sx={{ color: "#3C5A77" }} />
                }
                label="Zapamti prijavu"
                sx={{ color: "#3C5A77" }}
              /> */}
              <Button
                aria-busy={loginIsLoading}
                type="submit"
                fullWidth
                size="medium"
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 1,
                 
                bgcolor: "#1E3D59",
                "&:hover": { bgcolor: "#1B263B" },
              }}
              
              >
                Prijavi Se
              </Button>
              <span>{errorLogin && errorLogin}</span>
          {/*     <Button
                fullWidth
                sx={{
                  mt: 1,
                  mb: 2,
                  bgcolor: "#4A7097",
                  "&:hover": { bgcolor: "#2F5370" },
                }}
                variant="contained"
                size="medium"
                onClick={loginFacebook}
              >
                <FacebookIcon sx={{ color: "#F2F4F8", fontSize: "large" }} />{" "}
                Facebook Prijava
              </Button> */}
           {/*    <Button
                fullWidth
                sx={{
                  mt: 1,
                  mb: 2,
                  bgcolor: "#C62828",
                  "&:hover": { bgcolor: "#B71C1C" },
                }}
                variant="contained"
                size="medium"
                onClick={loginGoogle}
              >
                <GoogleIcon sx={{ color: "#F2F4F8", fontSize: "medium" }} />{" "}
                Google Prijava
              </Button> */}
              <Grid container>
                <Grid item xs>
                  <Link
                    href="/SignUp"
                    variant="body2"
                    sx={{ color: "#3C5A77" }}
                  >
                    Zaboravili Ste Šifru?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="/auth/registracija"
                    variant="body2"
                    sx={{ color: "#3C5A77" }}
                  >
                    {"Niste Registrovani? Registrujte Se"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
