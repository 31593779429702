import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';


const firebaseConfig = {
  apiKey: "AIzaSyC5kMlr7_jD48pTJUnFhxIQTQ5qFHd3baA",
  authDomain: "inmemoriambih.firebaseapp.com",
  databaseURL: "https://inmemoriambih-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "inmemoriambih",
  storageBucket: "inmemoriambih.appspot.com",
  messagingSenderId: "1034817487735",
  appId: "1:1034817487735:web:990326b5e1a61a747d9772",
  measurementId: "G-L5WDV1B9NX"
};



const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const subtractCredits = httpsCallable(functions, 'subtractCredits');
export const subtractCreditsForPersonCreation = httpsCallable(functions, 'subtractCreditsForPersonCreation');



export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LeEUVcnAAAAAA6J_wu4zQCRotyf4QaJPmXDRznd"),

  isTokenAutoRefreshEnabled: true
});



