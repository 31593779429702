import { db } from "../../../../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const createTextComment = async ({
  id,
  userId,
  textId,
  personId,
  author,
  avatar,
  status,
  badge,
  content,
  createdComAt,
}) => {
  try {
    await setDoc(doc(db, "textComments", id), {
      textId,
      personId,
      author,
      avatar,
      status,
      badge,
      reactions: {
                heart: 0,
              },
              userReactions: {},
      content,
      createdComAt,
      userId
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};


export const updateReactionOnTextComment = async ({ commentId, reaction, userId,avatar,author }) => {
  try {
    const commentRef = doc(db, "textComments", commentId);
    const comment = await getDoc(commentRef);

    const reactions = comment.data().reactions || {};
    reactions[reaction] = (reactions[reaction] || 0) + 1;

    const userReactions = comment.data().userReactions || {};
    userReactions[reaction] = userReactions[reaction] || [];
    if (!userReactions[reaction].some((userReaction) => userReaction.userId === userId)) {
      userReactions[reaction].push({ userId, avatar, author});
    }

    await updateDoc(commentRef, { reactions, userReactions })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  } catch (error) {
    console.error(error);
  }
};
