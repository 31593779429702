import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { formatDate, formatDistanceToNowCustom } from "../../../../utils";

////------ASYNC THUNK-------------------------
export const getAvatars = createAsyncThunk(
  "textCards/getAvatars",
  async (_, { getState, dispatch }) => {
    const docRef = doc(db, "textCards");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const heartData = docSnap.data().userReactions.heart || [];

      return heartData;
    }

    return [];
  }
);
export const getAvatas = createAsyncThunk(
  "avatars/getAvatars",
  async (texts, { rejectWithValue }) => {
    try {
      let avatars = [];

      texts.userReactions.heart.forEach((like) => {
        const author = like.author;
        const avatar = like.avatar;
        const userId = like.userId;

        avatars.push({ author, avatar, userId });
      });

      return avatars;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getText = createAsyncThunk(
  "textCards/getText",
  async (_, { getState, dispatch }) => {
    const response = await getDocs(collection(db, "textCards"));
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);


export const getTextByUser = createAsyncThunk(
  "textCards/getTextByUser",
  async (userId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "textCards"), where("userId", "==", userId))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);
export const getTextByPersonId = createAsyncThunk(
  "textCards/getTextByPersonId",
  async (personId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "textCards"), where("personId", "==", personId))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
       
        createdAt: formatDistanceToNowCustom(data.createdAt),
        dateEvent: formatDate(data.dateEvent),  
       /* soundRequests: formatSoundRequests(data.soundRequests), */
       
      };
    });
    return result;
  
  }
);

export const getTextReactions = createAsyncThunk(
  "textCard/getTextReactions",
  async (videoId, { getState, dispatch }) => {
    const response = await getDoc(collection(db, "textCard"), videoId);
    const result = response.data().reactions;
    return result;
  }
);
export const updateTextReactions = createAsyncThunk(
  "textCard/updateTextReactions",
  async (payload, { getState, dispatch }) => {
    const { textId, reaction } = payload;

    const cardRef = doc(db, "textCard", textId);
    const text = await getDoc(cardRef);
    const emotionValue = text.data().reactions[reaction];
    if (emotionValue) {
      await setDoc(cardRef, {
        reactions: {
          heart: "",
        },
      });
    } else {
      await updateDoc(cardRef, {
        reactions: {
          ...text.data().reactions,
          [reaction]: !emotionValue,
        },
      });
    }
  }
);

const initialState = {
  textCards: [],
  hearts: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
  textUpdated: false,
};

export const textSlice = createSlice({
  name: "textCards",
  initialState,
  reducers: {
    resetStateText: (state) => {
      return { ...initialState };
    },
    addTextCard: (state, { payload }) => {
      state.textCards.push(payload);
      state.textUpdated = true;
    },
    editText: (state, { payload }) => {
      const textIndex = state.textCards.findIndex(
        (text) => text.id === payload.id
      );
      if (textIndex >= 0) {
        state.textCards[textIndex] = {
          ...state.textCards[textIndex],
          ...payload,
        };
      } else {
        state.textCards.push(payload);
      }
      state.textUpdated = true;
    },
    deleteText: (state, { payload }) => {
      state.textCards = state.textCards.filter((text) => text.id !== payload);
      state.textUpdated = true;
    },

    addTextReaction: (state, { payload }) => {
      console.log("addTextReaction payload:", payload);
      const { textId, reaction, userReactions } = payload;

      const existingText = state.textCards.find((text) => text.id === textId);
      if (existingText) {
        existingText.reactions[reaction]++;
        if (userReactions) {
          if (!existingText.userReactions) {
            existingText.userReactions = {};
          }
          existingText.userReactions[reaction] = userReactions;
        }
      }
    },
  },
  prepare(
    id,
    cardAuthor,
    userId,
    personId,
    reactions,
    userReactions,
    cardDescription,
    dateEvent,
    createdAt,
    content,
    title
  ) {
    return {
      payload: {
        id,
        cardAuthor,
        userId,
        personId,
        reactions: {
          heart: 0,
        },
        userReactions: {
          heart: {},
        },
        content,
        cardDescription,
        dateEvent,
        createdAt,
        title,
      },
    };
  },
  extraReducers: (builder) => {
    builder
      .addCase(getText.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getText.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.textCards = payload;
        state.error = null;
        state.textUpdated = false; 
      })
      .addCase(getText.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      })
      .addCase(getTextByPersonId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTextByPersonId.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.textCards = payload;
        state.error = null;
        state.textUpdated = false; 
      })
      .addCase(getTextByPersonId.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      })
      .addCase(getTextByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTextByUser.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.textCards = payload;
        state.error = null;
        state.textUpdated = false; 
      })
      .addCase(getTextByUser.rejected, (state, { error }) => {
        state.status = "failed";
        state.error = error.message;
      }).addCase(resetStateText, (state) => {
        state.status = "idle";
         state.textCards = [];
         state.error = null;
       })
       .addCase(getAvatars.pending, (state) => {
        state.status = "loading";
        
      }).addCase(getAvatars.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hearts = action.payload;
      });
  },
});

export const { addTextCard,resetStateText, editText, deleteText, addTextReaction } =
  textSlice.actions;



export const selectAllTextCard = (state) => state.textCards.textCards;
export const getTextStatus = (state) => state.textCards.status;
export const getTextError = (state) => state.textCards.error;

const selectTextCardsState = (state) => state.textCards.textCards;
export const textUpdated=(state)=>state.textCards.textUpdated
// funkcija koja vraća isti textId 
const selectTextId = (_, textId) => textId;

// funkcija koja vraća isti userId
const selectUserId = (_, userId) => userId;
const selectPersonId = (_, personId) => personId;

// createSelector koristimo za memoizaciju rezultata
export const selectTextByTextId = createSelector(
  [selectTextCardsState, selectTextId],
  (textCards, textId) => textCards.find((text) => text.id === textId)
);

export const selectTextByUserId = createSelector(
  [selectTextCardsState, selectUserId],
  (textCards, userId) => textCards.filter((text) => text.userId === userId)
);

export const selectTextByPerosnId =createSelector(
  [selectTextCardsState, selectPersonId],
  (textCards, personId) => textCards.filter((text) => text.personId === personId)
);



const selectHearts = (state) => state.textCards.hearts;

export const selectAvatars = createSelector(
  [selectTextCardsState, selectHearts],
  (textCards, hearts) => {
    const avatars = [];

    hearts.forEach((like) => {
      const author = like.author;
      const avatar = like.avatar;
      const userId = like.userId;

      avatars.push({ author, avatar, userId });
    });

    return avatars;
  }
);

export default textSlice.reducer;
