import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { formatDate, formatSoundRequests } from "../../../utils";

export const getPersonsByPersonId = createAsyncThunk(
  "persons/getPersonsByPersonId",
  async (personId, { getState, dispatch }) => {
    const response = await getDocs(
      query(collection(db, "persons"), where("personId", "==", personId))
    );
    const result = response.docs.map((doc) => {
      const data = doc.data();
     
      return {
        ...data,
        id: doc.id,
        dateOfDeath: formatDate(data.dateOfDeath),
        createdAt: formatDate(data.createdAt),
        dateOfBirth: formatDate(data.dateOfBirth),
 /*        soundRequests: formatSoundRequests(data.soundRequests), */
      
      };
    });
    return result;
  }
);


const initialState = {
  person: [],
  status: "idle",
  error: null,
};

export const personsIdSlice = createSlice({
  name: "personsId",
  initialState,
  reducers: {resetStatePersonId: (state) => {
     
    return { ...initialState };
  
  }},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonsByPersonId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPersonsByPersonId.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.person = payload; 
      })
      .addCase(getPersonsByPersonId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { resetStatePersonId }= personsIdSlice.actions



  const selectPersonsIdState = (state) => state.personsId;

  // Memoizacija selektora
  export const selectPersonByPersonId = createSelector(
    [selectPersonsIdState, (_, personId) => personId],
    (personsIdState, personId) =>
      personsIdState.person.find((person) => person.personId === personId)
  );

export const getPersonsIddStatus = (state) => state.personsId.status;
export const getPersonsIddError = (state) => state.personsId.error;

export default personsIdSlice.reducer;