import React from "react";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
/*  import "./algoliaSearch.css"  */
import { Container, Box, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Button, Card, IconButton, Input, Typography } from "@mui/joy";
import {
  Configure,
  Highlight,
  InstantSearch,
  connectHits,
  connectSearchBox,
  connectStateResults,
  Stats,
} from "react-instantsearch-dom";
import { useNavigate } from "react-router-dom";

const searchClient = algoliasearch(
  "791GDSMUTE",
  "638dfd9f610648d58dec39c82c71b89b"
);
const CustomHits = connectHits(({ hits, handlePersonClick}) => {
  return (
    <Card
      sx={{
        borderTop: "2px solid #E1ECF8",
        borderBottom: "2px solid #E1ECF8",
      
      }}
    >
      {hits.map((hit) => (
        <Hit
          
          key={hit.objectID}
          hit={hit}
          handlePersonClick={handlePersonClick}
        />
      ))}
    </Card>
  );
});
const CustomSearchBox = ({
  currentRefinement,
  refine,
  setShowSearch,
  width,
}) => {
  return (
    <Input
      placeholder="Traži: Ime i Prezime..."
      variant="outlined"
      size="md"
      type="search"
      value={currentRefinement}
      onChange={(event) => {
        refine(event.currentTarget.value);
        setShowSearch(true);
      }}
      endDecorator={
        <IconButton
          variant="plain"
          onClick={() => {
            setShowSearch(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      }
      sx={{
        "--Input-radius": "40px",
        "--Input-paddingInline": "12px",
        width,
        borderRadius: "md",
        color: "#0C4DE",
        bgcolor: "transparent",
      }}
    />
  );
};
const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

const Results = connectStateResults(
  ({ searchState, searchResults,handlePersonClick }) => {
    if (searchState && searchState.query) {
      return (
        <><Stats
          translations={{
            stats(nbHits, timeSpentMS) {
              return `${nbHits} rezultata pronađeno za ${timeSpentMS}ms`;
            },
          }}
        />
        <CustomHits handlePersonClick={handlePersonClick} /> </>
      );
    } else {
      return null;
    }
  }
);
function AlgoliaSearch({ setShowSearch }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const width = isSmallScreen ? "80%" : "100%";
  const handlePersonClick = (path) => {
    setShowSearch(false);
    navigate(path);
  };
  return (
    <Container>
      <InstantSearch searchClient={searchClient} indexName="persons">
        <Configure hitsPerPage={5} />

        <Box display="flex" justifyContent="space-between">
          <Box>
            <ConnectedSearchBox setShowSearch={setShowSearch} width={width}  />
            <Results handlePersonClick={handlePersonClick} />
            
            
            
          </Box>
          
        </Box>
      </InstantSearch>
    </Container>
  );
}

export default AlgoliaSearch;

function Hit({ hit, handlePersonClick}) {
  return (
    <Box
  sx={{
    marginLeft: '10px',
    marginRight:'-25px',
  
  }}
>
<Button
  variant="plain"
  onClick={() => handlePersonClick(hit.path)}
  sx={{
    width: '100%',  
    display: "flex",
    flexDirection: "row", 
    alignItems: "left",
    justifyContent:'left',
    gap: "1",
    marginLeft: '-20px',  
  }}
>
  <Avatar src={hit?.personURL} alt={hit.id} />
  <Typography 
    level="h6" 
    sx={{ 
      color: "#E1ECF8", 
      fontSize: {
        xs: '0.75rem',  
        sm: '0.875rem', 
        md: '1rem',     
      },
     
      flexWrap: 'wrap',
      marginLeft: "5px",
    }}
  >
    <Highlight attribute="firstName" hit={hit} />
  </Typography>
  <Typography 
    level="h6" 
    sx={{ 
      color: "#E1ECF8", 
      marginLeft: "5px",
      fontSize: {
        xs: '0.75rem',  
        sm: '0.875rem',
        md: '1rem',
      },
      
      flexWrap: 'wrap',
    }}
  >
    <Highlight attribute="lastName" hit={hit} />
  </Typography>
</Button>
</Box>




  );
}
