import {
    createAsyncThunk,
    createSelector,
    createSlice,
  } from "@reduxjs/toolkit";
  import { collection, getDocs, query, where } from "firebase/firestore";
  import { db } from "../../../../firebase";
import { formatDistanceToNowCustom } from "../../../../utils";
  
  const initialState = {
    videoComments: [],
  };
  
  export const getVideoComments = createAsyncThunk(
    "videoComments/getVideoComments",
    async (_, { getState }) => {
      const response = await getDocs(collection(db, "videoComments"));
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
      
       
         
        };
      });
      return result;
    
    }
  );
  export const getCommentsVideoByPersonId = createAsyncThunk(
    "videoComments/getCommentsVideoByPersonId",
    async (personId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "videoComments"), where("personId", "==", personId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );
  export const getCommentsByVideoId = createAsyncThunk(
    "videoComments/getCommentsByVideoId",
    async (videoId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "videoComments"), where("videoId", "==", videoId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );

  
  export const getVideoCommentsByUser = createAsyncThunk(
    "videoComments/getVideoCommentsByUser",
    async (userId, { getState, dispatch }) => {
      const response = await getDocs(
        query(collection(db, "videoComments"), where("userId", "==", userId))
      );
      const result = response.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
         
          createdComAt: formatDistanceToNowCustom(data.createdComAt),
       
         
        };
      });
      return result;
    
    }
  );
  export const commentVideoSlice = createSlice({
    name: "videoComments",
    initialState,
    reducers: {
      resetStateCommentVideo: (state) => {
        return { ...initialState };
      },
      addVideoComment: (state, { payload }) => {
        state.videoComments.push(payload);
      },
      addReactionOnVideoComment: (state, { payload }) => {
        console.log('addReactionOnVideoComment payload:', payload); 
        const { commentId, reaction, userReactions } = payload;
          
        const existingComment = state.videoComments.find((comment) => comment.id === commentId);
        if (existingComment) {
          existingComment.reactions[reaction]++;
          if (userReactions) {
            if (!existingComment.userReactions) {
              existingComment.userReactions = {};
            }
            existingComment.userReactions[reaction] = userReactions;
          }
        }
      }
    },
    
    extraReducers: (builder) => {
      builder.addCase(getVideoComments.fulfilled, (state, { payload }) => {
        state.videoComments = payload;
      })
      .addCase(getCommentsByVideoId.fulfilled, (state, {payload})=>{
        state.videoComments = payload;
      }).addCase(getVideoCommentsByUser.fulfilled, (state, {payload})=>{
        state.videoComments = payload;
      }).addCase(getCommentsVideoByPersonId.fulfilled, (state, {payload})=>{
        state.videoComments = payload;
      })
      /* builder.addCase(createComment.pending, (state) => {
    // Možete postaviti loading status ili raditi nešto slično ako je potrebno
  });
  builder.addCase(createComment.fulfilled, (state, { payload }) => {
    // Ažurirajte state sa novim komentarom, ako je potrebno
  });
  builder.addCase(createComment.rejected, (state, { error }) => {
    // Postavite error status ili radite nešto slično ako je potrebno
  }); */
    },
  });
  
  export const { addVideoComment,addReactionOnVideoComment,resetStateCommentVideo } = commentVideoSlice.actions;
  
  export default commentVideoSlice.reducer;

 
  
    export const selectVideoCommentByPersonId = createSelector(
      [(state) => state.videoComments.videoComments, (state, personId) => personId],
      (videoComments, personId) => videoComments.filter((comment) => comment.personId === personId)
    );
    export const selectVideoCommentByVideoId = createSelector(
      [(state) => state.videoComments.videoComments, (state, videoId) => videoId],
      (videoComments, videoId) => videoComments.filter((comment) => comment.videoId === videoId)
    );
    
    export const selectVideoCommentById = createSelector(
      [(state) => state.videoComments.videoComments, (state, commentId) => commentId],
      (videoComments, commentId) => videoComments.filter((comment) => comment.id === commentId)
    );
    
    export const selectVideoCommentByUserId = createSelector(
      [(state) => state.videoComments.videoComments, (state, userId) => userId],
      (videoComments, userId) => videoComments.filter((comment) => comment.userId === userId)
    );