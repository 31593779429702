import { db } from "../../../../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const createComment = async ({
  id,
  userId,
  cardId,
  personId,
  author,
  avatar,
  status,
  badge,
  content,
  createdComAt,
}) => {
  try {
    await setDoc(doc(db, "comments", id), {
      cardId,
      personId,
      author,
      avatar,
      status,
      badge,
      reactions: {
                thumbsUp: 0,
                wow: 0,
                heart: 0,
                rocket: 0,
                coffee: 0,
              },
              userReactions: {},
      content,
      createdComAt,
      userId
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};


export const updateReactionCount = async ({ commentId, reaction, userId,avatar, author }) => {
  try {
    const commentRef = doc(db, "comments", commentId);
    const comment = await getDoc(commentRef);

    const reactions = comment.data().reactions || {};
    reactions[reaction] = (reactions[reaction] || 0) + 1;

    const userReactions = comment.data().userReactions || {};
    userReactions[reaction] = userReactions[reaction] || [];
    if (!userReactions[reaction].some((userReaction) => userReaction.userId === userId)) {
      userReactions[reaction].push({ userId, avatar, author});
    }

    await updateDoc(commentRef, { reactions, userReactions })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  } catch (error) {
    console.error(error);
  }
};
