import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginService, registerService, logoutService,updateUserService, loginWithFacebookService, loginWithGoogleService } from './authService';
import { collection, addDoc, onSnapshot, doc } from 'firebase/firestore'; 
import { loadStripe } from '@stripe/stripe-js';
import { auth, db } from "../../../firebase";


export const initiateCheckout = createAsyncThunk(
  "userAuth/initiateCheckout",
  async ({priceId, credits}, { getState }) => {
    const user = auth.currentUser;

      console.error('krediti!',credits);
   


    console.log('Initiating checkout...', user);
    const checkoutSessionRef = collection(db, "users", user.uid, "checkout_sessions");
    const docRef = await addDoc(checkoutSessionRef, {
        price: priceId,
        mode: 'payment',
        success_url: "https://www.inmemoriam-bosna.ba/auth/prijava",
        cancel_url: window.location.origin,
        // Dodajte kredite u metapodatke
        metadata: {
            credits: credits,
        },
    });
    console.log('Added doc to checkout_sessions: ', docRef.id);

    const unsub = onSnapshot(doc(checkoutSessionRef, docRef.id), async (snap) => {
        const data = snap.data();
        console.log('Got snapshot: ', data);
        const { error, sessionId } = data;
        if (error) {
            // Handle any errors
            console.log('Error occurred: ', error);
            throw new Error(`An error occured: ${error.message}`);
        } else if (sessionId) {
            // We have a session, let's redirect to Checkout
            console.log('Got sessionId: ', sessionId);
            // Init Stripe
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH);
            if (!stripe) {
                console.log('Problem loading Stripe');
                throw new Error('Problem loading Stripe');
            }
            console.log('Redirecting to checkout...');
            await stripe.redirectToCheckout({ sessionId });
        }
    });

    // Don't forget to unsubscribe when the component is unmounted or the effect is re-running
    return unsub;
});






export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: {
    userData: null,
    loginStatus: false,
    loading: true,
    error:null,
  },
  reducers: {
    setLoginStatus: (state, { payload: { loginStatus, loading, userData } }) => {
      state.loginStatus = loginStatus;
      state.loading = loading;
      state.userData = userData;
    },
    updateUser: (state, { payload: { updatedData } }) => {
      // Assuming that userData is an object and updatedData is the new data to be updated.
      state.userData = { ...state.userData, ...updatedData };
    },
    removeActiveUser: (state, action) => {
      state.loginStatus = false;
      state.userData = null;
    },
    updateCredits: (state, { payload: { credits } }) => {
      state.userData.credits = credits;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initiateCheckout.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(initiateCheckout.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(initiateCheckout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const { setLoginStatus, removeActiveUser,updateUser,updateCredits } = userAuthSlice.actions;
export default userAuthSlice.reducer;



export const login = ({ email, password }) => async (dispatch) => {
  const userObject = await loginService(email, password);
  const userData = userObject.user;
  

  dispatch(
    setLoginStatus({
      loginStatus: true,
      loading: false,
      userData: {
        uid: userData.uid,
        displayName: userData.displayName,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        photoURL: userData.photoURL,
        dateRegistration: userData.dateRegistration,
        credits: userData.credits,
      },
    })
  );
};

//LOGIN WITH GOOGLE ADN FACEBOOK
export const loginWithGoogle = () => async (dispatch) => {
  const userData = await loginWithGoogleService();
  
  
  dispatch(
    setLoginStatus({
     
      loading: false,
      userData: {
        uid: userData.uid,
        displayName: userData.displayName,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        photoURL: userData.photoURL,
        dateRegistration: userData.dateRegistration,
        credits: userData.credits, 
        
      },loginStatus: true,
    })
  );
};

export const loginWithFacebook = () => async (dispatch) => {
  const userData = await loginWithFacebookService();
 /*  console.log('ovo je userdata', userData); */
  
  dispatch(
    setLoginStatus({
      loginStatus: true,
      loading: false,
      userData: {
        uid: userData.uid,
        displayName: userData.displayName,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        photoURL: userData.photoURL,
        dateRegistration: userData.dateRegistration,
        credits: userData.credits, 
      },
    })
  );
};



export const register = ({ firstName, lastName, email, password, dateRegistration}) => async (dispatch) => {
  try {
    const userCredential = await registerService({ firstName, lastName, email, password, dateRegistration });
    if (userCredential && userCredential.user) {
    /*   try {
        await sendEmailVerification(userCredential)
      } catch (error) {
        console.log("noje poslan emal verigikacije",error);
        // Dodajte dodatnu obradu greške ovde, ako je potrebno.
      } */

      dispatch(
        setLoginStatus({
          loginStatus: true,
          loading: false,
          userData: {
            uid: userCredential.user.uid,
            displayName: `${firstName} ${lastName}`,
            firstName,
            lastName,
            email: userCredential.user.email,
            dateRegistration,
            credits: userCredential.user.credits, // Osigurajte da userCredential.user ima credits ključ.
          },
        })
      );
    } else {
      // Obrada greške ako userCredential.user ne postoji.
    }
  } catch (error) {
    console.log(error);
    // Ovde možete dodati akciju za postavljanje statusa greške u Redux store-u.
    // Na primer: dispatch(setLoginError(error));
  }
};


export const updateUserData = (updatedData) => async (dispatch) => {
  const updatedUser = await updateUserService(updatedData);
  dispatch(updateUser({ updatedData: updatedUser }));
};


export const logout = () => async (dispatch) => {
  await logoutService();
  dispatch(setLoginStatus({ loginStatus: false, loading: false, userData: null }));
};

export const selectUserData = (state) => {
  return state.users.userData;
};

export const selectUserCreatedId=(state, userId) =>
  state.users.userData.filter(user => userId === user.uid);
