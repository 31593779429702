import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
  } from "firebase/storage";
 
  import {
    deleteDoc,
    doc,
    getDoc,
    setDoc,
    updateDoc,
  } from "firebase/firestore";

import { db, storage } from "../../../../firebase";


const updloadImageCard = async (id, personURL, onProgress) => { 
const storageRef = ref(storage, `image-cards/${id}-${Date.now()}`);
  const uploadTask = uploadBytesResumable(storageRef, personURL);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (error) => {
       /*  console.log(error.message); */
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        /* console.log("File available at", downloadURL); */
        resolve(downloadURL);
      }
    );
  });
};
  
  export const createImageCard = async ({
    id,
    fileURL,
    personId,
    cardAuthor,
    userId,
    cardDesc,
    dateEvent,
    createdAt,
    onProgress,
  }) => {
    try {
      const downloadURL = await updloadImageCard(id, fileURL, onProgress);
    const docRef = doc(db, "cards", id);
    const reactions={
      heart: 0,
    };
    const userReactions = {};
    const cardData={id,
      userId,
      personId,reactions,userReactions,cardAuthor,
      fileURL: downloadURL,
      cardDesc,
      dateEvent,
      createdAt,};
              await setDoc(docRef,cardData);
              /* console.log("Document successfully added"); */
            } catch (e) {
      /* console.error("Error adding document: ", e); */
    }
  };
  
  // Update the card data without changing the file URL
  export const updateImageCard = async ({
    id,
    fileURL,
    cardDesc,
    dateEvent,
    onProgress,
  }) => {
    try {
      const cardRef = doc(db, "cards", id);
    
  const cardData={
    ...(cardDesc && { cardDesc }),
    ...(dateEvent && { dateEvent }),
    ...(fileURL && { fileURL }),
  }

      await updateDoc(cardRef,cardData,onProgress,);
    } catch (e) {
      console.log("ovo je fileURL",fileURL)
      console.error("Error updating card: ", e);
    }
  };

// Update the fileURL from imageCard 

  export const updateFileUrlImageCard = async (
    id, 
  oldPictureCardURL,
  pictureCardFile,
  onProgress,
  setUploading
) => {
  setUploading(true);
  if (oldPictureCardURL) {
    try {
      const storageRef = ref(storage, oldPictureCardURL);
      await deleteObject(storageRef);
      console.log("Stara slika je uspjesno izbrisana");
    } catch (error) {
      console.log("Error deleting old image:", error);
    }
  }

  // Postavi novu sliku
  if (pictureCardFile) {
    try {
      const storageRef = ref(storage, `image-cards/${id}-${Date.now()}`);
      const uploadTask = uploadBytesResumable(storageRef, pictureCardFile);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (typeof onProgress === 'function') {
              onProgress(progress);
            } else {
              console.error("onProgress nije funkcija");
            }
          },
          (error) => {
            console.error("Error uploading file:", error);
            reject(error);
          },
          async () => {
            const fileURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploading(false);
            resolve(fileURL);
            console.log("ovo je fileURL",fileURL)
          }
          
        );
      });
    } catch (error) {
      console.error("Error uploading new image:", error);
      setUploading(false);
      throw error;
    }
  }
};
  
  export const deleteImageCard = async ({ cardId }) => {
    try {
      const cardRef = doc(db, "cards", cardId);
      await deleteDoc(cardRef);
    } catch (error) {
      console.error(error);
    }
  };
  
  export const updateReactionCount = async ({ cardId, reaction, userId, avatar, author }) => {
    try {
      const cardRef = doc(db, "cards", cardId);
      const card = await getDoc(cardRef);
  
      const reactions = card.data().reactions || {};
      reactions[reaction] = (reactions[reaction] || 0) + 1;
  
      const userReactions = card.data().userReactions || {};
      userReactions[reaction] = userReactions[reaction] || [];
  
      if (!Array.isArray(userReactions[reaction])) {
        userReactions[reaction] = [];
      }
  
      if (!userReactions[reaction].some((userReaction) => userReaction.userId === userId)) {
        userReactions[reaction].push({ userId, avatar, author });
      }
  
      await updateDoc(cardRef, { reactions, userReactions })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  
  