import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { Suspense, lazy } from "react";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//HOOKS
import { useAuth } from "./hooks";
//PAGES
import Footer from "./pages/footer/Footer";


//COMPONENTS
import Loading from "./utils/Loading";
import AuthRoutes from "./routes/AuthRoutes";
import { CssBaseline } from "@mui/material";
import Header from "./pages/header/Header";
import InfoRoutes from "./routes/InfoRoutes";
import NotFoundPage from "./pages/NotFoundPage";

const toastContainerStyle = {
  marginTop: '5rem',
};

const HomePage = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(import("./pages/home/Home"));
      }, 100);
    })
);
const PersonRoutes = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(import("./routes/PersonRoutes"));
      }, 100);
    })
);
const ProfileRoutes = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(import("./routes/ProfileRoutes"));
      }, 100);
    })
);


function App() {
  useAuth();
  const isLoading = useSelector((state) => state.users.loading);
  if (isLoading) {
    return <Loading />;
  }
  
  
  return (
 <>
      <CssBaseline/>
      <ToastContainer style={toastContainerStyle}/>
      <Router>
      
        <Header />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="loadingStripe" element={<Loading />}/>
            <Route path="info/*" element={<InfoRoutes />} />
            <Route path="persons/*" element={<PersonRoutes />} />
            <Route path="profile/*" element={<ProfileRoutes />} />
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
      </>
  );
}

export default App;
