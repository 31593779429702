import React from 'react';
//MATERIAL UI
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { Avatar, Box, Divider, Typography } from '@mui/joy';
//UTILS
import { toTitleCase } from '../../utils';


const ProfileMenu = ({ anchorEl, open, handleClose, navigate, logOut,user,menuId}) => {

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      variant="soft"
      sx={{
        paddingTop: {xs:'2rem',sm:'1.5rem'},
        background: "linear-gradient( 135deg, #1B263B, #2F5370)",
      }}
      aria-controls={menuId}
      onClose={handleClose}
      
     
    > <Typography level='h5' sx={{ paddingLeft: "0.8rem" ,color:'#AFC0D5',textAlign:'start'}}>Profil</Typography><Divider sx={{ padding: "1px" }} />
      <MenuItem
       onClick={() => {
          handleClose();
          setTimeout(() => {
            navigate(`/profile/${user?.uid}`);
          }, 100);
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      > <Box sx={{ display: 'flex', gap: 2 }}> 
       <Avatar size='sm' src={user?.photoURL}/> 
       {user?.displayName && toTitleCase(user.displayName)}
          </Box>
       
         
      </MenuItem>
      <Divider sx={{ padding: "1px" }} />
      <MenuItem
        onClick={() => {
          handleClose();
          logOut();
          setTimeout(() => {
            navigate("/");
          }, 100);
        }}
        sx={{
          color: "#E1ECF8",
          "&:hover": {
            color: "#1B263B",bgcolor:'#3C5A77'
          },
        
        }}
      >
        Odjava
       
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
