import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { Login, Register } from '../components/Authentication';

const AuthRoutes = () => {
  return (
    <Routes>
    <Route path="prijava" element={<Login/>} />
    <Route path="registracija" element={<Register/>} />
  </Routes>
  );
};
export default AuthRoutes;
