import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
    getMetadata,
    updateMetadata,
  } from "firebase/storage";

  import {
    deleteDoc,
    doc,
    getDoc,
    setDoc,
    updateDoc,
  } from "firebase/firestore";
import { db, storage } from "../../../../firebase";
  

  export const createVideoCard = async ({
    id,
    video,
    title,
    personId,
    cardAuthor,
    userId,
    cardDesc,
    dateEvent,
    createdAt,
    setProgress,
  }) => {
    try {
      const storageRef = ref(storage, `videoGallery/${personId}/${Date.now()+ title} `);
      const uploadTask = uploadBytesResumable(storageRef, video);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          
          setProgress(progress);
        },
        (error) => {
          console.log(error.message);
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(
            async (downloadURL) => {
              await setDoc(doc(db, "videoCards", id), {
                id,
                userId,
                personId,
                title,
                reactions: {
                  heart: 0,
                },
                userReactions: {},
                cardAuthor,
                video: downloadURL,
                cardDesc,
                dateEvent,
                createdAt,
              });
              
              console.log("Fajl je dostupan na:", downloadURL);
              
            }
          );
        }
      );
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  
  
  

  export const updateVideoCard = async ({
    id,
    video,
    cardDesc,
    dateEvent,
    setProgress,
  }) => {
   
    try {
      const cardRef = doc(db, "videoCards", id);
      const cardData={
        ...(cardDesc && { cardDesc }),
        ...(dateEvent && { dateEvent }),
        ...(video && { video }),
      }
      await updateDoc(cardRef, cardData,setProgress);
     
      
    } catch (e) {
      console.log("ovo je video",video)
      console.error("Error updating card: ", e);
    }
  };
  export const updateVideoFile = async ({
    id,
    video,
    personId,
    setProgress,
    setUploading,
  }) => {
    try {
      const cardRef = doc(db, "videoCards", id);
      const card = await getDoc(cardRef);
  
      const oldFileURL = card.data().video;
      setUploading(true);
      // Ako video datoteka nije pružena, vrati prethodnu URL adresu videozapisa
      if (!video) {
        return oldFileURL;
      }
  
      const storageRef = ref(
        storage,
        `videoGallery/${personId}/${Date.now()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, video);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress);
          setProgress(progress);
        
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const cardRef = doc(db, "videoCards", id);
          const card = await getDoc(cardRef);
  
          const oldFileURL = card.data().video;
          if (video !== oldFileURL) {
            const oldFileRef = ref(
              storage,
              `videoGallery/${personId}/${oldFileURL}`
            );
  
            try {
              // Provjerite postoji li datoteka prije nego je pokušate izbrisati
              await getMetadata(oldFileRef);
              // Ako datoteka postoji, izbrišite je
              await deleteObject(oldFileRef);
              console.log("Deleted old file with URL:", oldFileURL);
            } catch (error) {
              if (error.code === "storage/object-not-found") {
                console.log(
                  "Old file with URL",
                  oldFileURL,
                  "does not exist"
                );
              } else {
                console.error("Error deleting old file:", error);
              }
            }
          }
  
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          // Postavite metapodatke za novu datoteku
          const metadata = {
            contentType: "video/mp4",
            customMetadata: {
              personId: personId,
              cardId: id,
            },
          };
          await updateMetadata(uploadTask.snapshot.ref, metadata);
  
          await updateDoc(cardRef, {
            id,
            video: downloadURL,
          });
  
          console.log("File available at", downloadURL);
  
        }
      );
    } catch (error) {
      console.error("Error updating file URL: ", error);
      setUploading(false);
    }
  };
  
  export const deleteVideoCard = async ({ videoId }) => {
    try {
      const cardRef = doc(db, "videoCards", videoId);
      await deleteDoc(cardRef);
    } catch (error) {
      console.error(error);
    }
  };
  
  export const updateReactionVideoCard = async ({ videoId, reaction, userId,avatar, author }) => {
    try {
      const cardRef = doc(db, "videoCards", videoId);
      const video = await getDoc(cardRef);
  
      const reactions = video.data().reactions || {};
      reactions[reaction] = (reactions[reaction] || 0) + 1;
  
      const userReactions = video.data().userReactions || {};
      userReactions[reaction] = userReactions[reaction] || [];
     
      if (!Array.isArray(userReactions[reaction])) {
        userReactions[reaction] = [];
      }
      
      if (!userReactions[reaction].some((userReaction) => userReaction.userId === userId)) {
        userReactions[reaction].push({ userId, avatar, author });
      }
      
  
      await updateDoc(cardRef, { reactions, userReactions })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  
  export const PromiseProgressCard = async ({
    id,
    cardAuthor,
    personId,
    reactions,
    userId,
    video,
    title,
    cardDesc,
    dateEvent,
    createdAt,
    setProgress,
  }) => {
    const promise = new Promise((resolve, reject) => {
      createVideoCard({
        id,
        cardAuthor,
        personId,
        reactions,
        userId,
        video,
        title,
        cardDesc,
        dateEvent,
        createdAt,
        setProgress: (progress) => {
          setProgress(progress);
          if (progress === 100) {
            resolve();
          }
        },
      });
    });
    return promise;
  };

  export const PromiseProgressUpdateCard = async ({
    id,
    cardAuthor,
    personId,
    reactions,
    userId,
    video,
    title,
    cardDesc,
    dateEvent,
    createdAt,
    setProgress,
  }) => {
    const promise = new Promise((resolve, reject) => {
      createVideoCard({
        id,
        cardAuthor,
        personId,
        reactions,
        userId,
        video,
        title,
        cardDesc,
        dateEvent,
        createdAt,
        setProgress: (progress) => {
          setProgress(progress);
          if (progress === 100) {
            resolve();
          }
        },
      });
    });
    return promise;
  };